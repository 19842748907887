/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Link, IndexLink } from 'react-router-dom';
import Fullscreen from "react-full-screen";
import WeatherMapComponent from "../../layouts/weathermap";
// Import custom components
import Search from './search.component';
import UserMenu from './user-menu.component';
import Notification from './notification.component';
import Language from './language.component';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import $ from "jquery";
var redirectURL = require('../../redirectURL');

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        var localRole =  localStorage.getItem("roles");
        if(localRole != "CUSTOMER" && localRole != "MARKETING")
        {
            var selectOperation = { label: "OPERATIONS", value: localRole }
        }
        else
        {
            var selectOperation = { label: localRole, value: localRole }
        }
        this.state = {
            modules: AllModules,
            sidebar: false,
            topbar: false,
            navMenus: false,
            outerb: 'show-m',
            outerb1: 'show-n',
            overly: "show-n",
            sliderRouteTranslate: "",
            sliderTranslate: "",
            pbodyc: 'pbdy',
            tpmenu: require('../../../assets/icons/TOP-NAV.png'),
            sTop: 'tp40',
            mTop: 'tp10',
            showAlert: "show-m",
            tmenu: '',
            searchbar: false,
            loginType: "",
            userroles: "",
            departmentData: [],
            detailedWeatherMap: 0,
            customersList : [],
            customercode : [],
            plantList:[],
            plantcode :[{value:"",label:"ALL"}],
            selectOperation:selectOperation,
            selectOptions: [{ label: "CUSTOMER", value: "CUSTOMER" }, { label: "OPERATIONS", value: "AMNS_OPS_SC" }],

        }
    }

    goFull = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    openCloseSidebar = () => {
        if (this.state.sidebar) {
            this.setState({ sidebar: false })
            document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        } else {
            this.setState({ sidebar: true })
            document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
        }
    }

    toggle() {
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }


    openCloseTopbar = () => {
        if (this.state.topbar) {
            this.setState({
                topbar: false,
                tpmenu: require('../../../assets/icons/TOP-NAV.png'),
                outerb: "show-m",
                outerb1: "show-n",
                sTop: "40px",
                tmenu: '',
                mTop: 'tp10',

            })
            document.querySelector(".page-body").classList.remove('pbdy');
            document.querySelector(".page-main-header").classList.remove('topbar-close');
            document.querySelector('.page-sidebar').classList.remove("tp0");
            document.querySelector('.page-sidebar').classList.add("tp40");
        } else {
            this.setState({
                topbar: true,
                tpmenu: require('../../../assets/icons/topnav-blue.png'),
                outerb: "show-n",
                outerb1: "show-m",
                sTop: "0px",
                tmenu: '',
                mTop: 'tp40 r42',
            })
            document.querySelector(".page-body").classList.add('pbdy');
            document.querySelector(".page-main-header").classList.add('topbar-close');
            document.querySelector('.page-sidebar').classList.remove("tp40");
            document.querySelector('.page-sidebar').classList.add("tp0");
        }
    }


    componentWillMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            this.setState({ sidebar: false })
        }
    }

    componentDidMount() {
        //console.log("Header Sidebar :", this.state.sidebar);
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        }
        this.setState({
            loginType: localStorage.getItem("user_type"),
            userroles: localStorage.getItem("roles"),
        })
        var loading_port_code = localStorage.getItem("loading_port_code");
        if (loading_port_code != undefined) {
            $("#loadingPlant").val(loading_port_code)
        }
        console.log(localStorage.getItem('dept_code'), "deptCode")
        console.log(localStorage.getItem('is_enmovil_transporter'), "is_enmovil_transporter")
        if(localStorage.getItem('user_type') == "MARKETING")
        {
            if(localStorage.getItem('customersData') !=undefined && localStorage.getItem('customersData') !="undefined" && localStorage.getItem('customersData') !="")
            {
                this.loadCustomersDataList();
            }
            else
            {
                redirectURL.post("/consignees/customers")
                .then(async(response) => {
                    var customersData = response.data;
                    if(customersData.length > 0)
                    {
                        await localStorage.setItem('customersData', JSON.stringify(customersData));
                        await this.loadCustomersDataList();
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
            }
        }
        if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0){
            redirectURL.post("/consignees/plantsData").then(async(response)=>{
                // console.log(response.data,"177")
                let selectedPlant = response.data.filter(e => e.value == localStorage.getItem("pc"))
                var resp = [{"value":"" ,"label":"ALL"}];
                var plantList = [...resp, ...response.data]
                this.setState({
                    plantList : plantList,
                    plantcode: selectedPlant[0]
                })
            })
        }
        
       
    }
    loadCustomersDataList = async() => {
        var customercode = [];
        var customersData = JSON.parse(localStorage.getItem('customersData'));
        // console.log(customersData,"customersData")
        var customer_code = localStorage.getItem("original_customer_code");
        var sel_customer_code = localStorage.getItem("customer_code");
        if(customer_code !=undefined && customer_code !="")
        {
            customer_code = JSON.parse(customer_code);
            // console.log(typeof(customer_code),customer_code,"customer_code")
            var customerDetails = [];
            customer_code.map(function(pt){
                customersData.map(function(e){
                    if(e.customer_code == pt)
                    {
                        // console.log("matched",e)
                        customerDetails.push(e);
                    }
                })
            });
            if(customerDetails.length > 0)
            {
                customerDetails = customerDetails.sort(SortByName);
            }
            var customersList = [{"label" : "All Customers","value" : "all"}];
            customerDetails.map(function(p){
                customersList.push({
                    "label" : p.customer_code+" - "+p.consignee_name,
                    "value" : p.customer_code,
                });
            });
            if(sel_customer_code !=undefined && sel_customer_code !="")
            {
                var customerDetails = [];
                var sel_customer_code = JSON.parse(sel_customer_code);
                sel_customer_code.map(function(pt){
                    customersData.map(function(e){
                        if(e.customer_code == pt)
                        {
                            customerDetails.push(e);
                        }
                    })
                });
                if(customerDetails.length == 1)
                {
                    customercode = [{"label" : customerDetails[0].customer_code+" - "+customerDetails[0].consignee_name,"value" : customerDetails[0].customer_code}]
                }
                else
                {
                    customercode = [{"label" : "All Customers","value" : "all"}];
                }
            }
            // console.log(customersList,"customersList")
            this.setState({
                customersList : customersList,
                customercode : customercode,
            });
        }
        else
        {
            window.location.href="/logout";
        }
    }
    changeCustomerData(customercode){
        console.log("option ",customercode)
        this.setState({
            customercode : customercode,
        });
        if(customercode.value !="all")
        {
            var customercodeval = [customercode.value];
            customercodeval = JSON.stringify(customercodeval);
           localStorage.setItem("customer_code",customercodeval);
        }
        else
        {
            localStorage.setItem("customer_code",localStorage.getItem("original_customer_code"));
        }
        window.location.reload();
    }
    
    changePlantData(plantcode){
        // console.log("option ",plantcode)
        this.setState({
            plantcode : plantcode,
        });
        if(plantcode.value !="all")
        {
            // var plantcodeval = [plantcode.value];
            // plantcodeval = JSON.stringify(plantcodeval);
           localStorage.setItem("pc",plantcode.value);
        }
        else
        {
            localStorage.setItem("pc",localStorage.getItem("pc"));
        }
        var selPlant = this.state.plantList.filter((pl)=>pl.value == plantcode.value);
        if(selPlant.length > 0)
        {
            if(selPlant[0].is_interplant_enabled == 1)
            {
                localStorage.setItem("is_interplant_enabled",1);
                window.location.reload();
            }
            else
            {
                window.location.href="/sndtrucks";
            }
        }
        else
        {
            window.location.href="/sndtrucks";
        }
    }
    
    changeOperations=(selectOperation)=>{
       
        var selectType = selectOperation.value
        this.setState({selectOperation:selectOperation})
       
        
        var udetails = JSON.parse(localStorage.getItem("udetails"));
        console.log(udetails,"udetails")
        localStorage.setItem("roles",selectType)
        if(selectType == "CUSTOMER")
        {
            localStorage.setItem("user_type","CUSTOMER")
            localStorage.setItem("customer_code",udetails.customer_code)
            localStorage.setItem("pc","")
        }
        if(selectType == "AMNS_OPS_SC" || selectType == "AMNS_OPS")
        {
            localStorage.setItem("user_type","ORGUSER")
            localStorage.setItem("pc",udetails.plant_code)
            localStorage.setItem("customer_code","")
        }
        redirectURL.post("/amns/getmenus",{roles:selectType})
        .then(async(resp) => {
            var response = resp.data 
            console.log(response,"response")
            var usermenucodes = response.result[0].menus
           

            
            localStorage.removeItem("usermenucodes")
            localStorage.setItem("usermenucodes",JSON.stringify(usermenucodes))
           
            console.log(localStorage.getItem("usermenucodes"),"1234") 
            var usermenus = await parseMenus(response.menuDetails)

            console.log(usermenus,"usermenus1233")
             localStorage.removeItem("usermenus")
            localStorage.setItem("usermenus",JSON.stringify((usermenus)))
            
           

            if(selectType == "AMNS_OPS_SC"){
                var dashboardUrl = "sndtrucks";	
            }
            else if(selectType == "AMNS_OPS")
            {
                var dashboardUrl = "shipmenttrucks";		
            }
            else if(selectType == "security")
            {
            var dashboardUrl = "securitydashboard";		
            }
            else if(selectType == "vehicletrackingreport")
            {
                var dashboardUrl = "tpttruckgpsstatus";		
            }
            else if(selectType == "CUSTOMER")
            {
                var dashboardUrl = "customertrucks";		
            }
            else if(selectType == "RAIL")
            {
                var dashboardUrl = "rail-consignments/default";		
            }
            else if(selectType == "TRANSPORTER")
            {
                var dashboardUrl = "sndtranshipments";		
            }
            else{
                var dashboardUrl = "shipmenttrucks";		
            }
              window.location.href = dashboardUrl     
        })
    this.setState({ open: false });
   
       
    }
    changeHandler = (e) => {
        this.setState({
            sliderRouteTranslate: "slider-translate-60p",
            overly: "show-m",
        })
    }
    onChangeLoadingPlant = (e) => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var selectedPlant = $("#loadingPlant").val();
        var params = {
            selectedPlant: selectedPlant,
        }
        redirectURL.post("/lmconsignees/getLoadingPlantDetails", params)
            .then((response) => {
                var res = response.data;
                this.setState({
                    loadshow: 'show-n',
                    overly: 'show-n',
                })
                localStorage.setItem('loading_port_code', selectedPlant);
                localStorage.setItem('comp_code', res[0].comp_code);
                window.location.reload();
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }
    onClickHideAll = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            sliderTranslate: "",
            overly: "show-n"
        })
    }
    closeMenuAlert() {
        this.setState({
            showAlert: "show-n"
        })
    }
    onCloseRouteDiv = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            overly: "show-n"
        })
    }
    onShowUploadDiv = () => {
        //console.log("Click DCT Users Button")
        var requestParam = {
            userType: "DCTUSER"
        }
        redirectURL.post("/consignments/getUsersByUserType", requestParam)
            .then((response) => {
                var departments = response.data;
                //console.log("records",records)
                if (departments.length > 0) {
                    redirectURL.post("/consignments/getUserLoginDetails", { "departments": departments }).then((response) => {
                        console.log("response", response.data)
                        this.setState({
                            departmentData: response.data,
                            loadshow: 'show-n',
                            overly: 'show-n',
                        })
                    }).catch((e) => {
                        console.log(e);
                    })
                }
                else {
                    this.setState({
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                }
                this.setState({
                    uploadDivWidth: '30%',
                    sliderTranslate: "slider-translate",
                    showDiv: 'show-m',
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            showDiv: 'show-n',
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        var sort = [
            {
                colId: 'username',
                sort: 'asc',
            },
        ];
        this.gridApi.setSortModel(sort);
    };

    render() {
        var columnwithDefsForDCT = [
            {
                headerName: "User Name",
                field: "username",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: "Email",
                field: "email",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Phone",
                field: "phone",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Online/Offline",
                field: "loginStatus",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                cellRenderer: function (params) {
                    if (params.data.loginStatus == 1) {
                        return '<i class="fa fa-circle" style="color:green" </i>';
                    }
                    else {
                        return '<i class="fa fa-circle" style="color:yellow" </i>';
                    }
                },
            }
        ]
        const SearchIcon = require('../../../assets/images/dashboard/search.png');
        var searchStyle = {};
        if (localStorage.getItem('dept_code') == 'SNDG' || localStorage.getItem('dept_code') == '"SNDG"') {
            if (localStorage.getItem('roles').indexOf("Plant") >= 0 || localStorage.getItem('roles').indexOf("RSO") >= 0
                || localStorage.getItem('roles').indexOf("Loading Officer") >= 0
                || localStorage.getItem('roles').indexOf("Unloading Officer") >= 0
                || localStorage.getItem('roles').indexOf("BA") >= 0) {
                var dept = ''

            }
            else {
                var dept = 'Sales and Dispatch'

            }
        }
        if (localStorage.getItem('dept_code') == 'LOG-PRT' || localStorage.getItem('dept_code') == '"LOG-PRT"') {
            var dept = 'Spare Parts'
        }
        if (localStorage.getItem('dept_code') == 'LOG-TNP' || localStorage.getItem('dept_code') == '"LOG-TNP"') {
            var dept = 'Train and Production'
        }
        if (localStorage.getItem('dept_code') == 'LOG-SC' || localStorage.getItem('dept_code') == '"LOG-SC"') {
            var dept = 'Supply Chain'
        }
        var noPadStyle = {};
        if (localStorage.getItem('is_enmovil_transporter') != 1 && localStorage.getItem('is_load_management') != 1) {
            var noPadStyle = { "padding": "0px" };
        }
        let userType = localStorage.getItem('user_type') == 'TECHUSER'
        var is_multiple_roles = localStorage.getItem("is_multiple_roles");
        var selRoleCheck = 0;
        if(is_multiple_roles !=undefined && is_multiple_roles !="")
        {
            selRoleCheck = is_multiple_roles;
            
        }
        console.log(selRoleCheck,"selRoleCheck")
        return (
            <div className="page-main-header cus-main-header" id="pheader">
                <div className="main-header-left">
                    <div className="logo-wrapper">
                        <a href="/">
                            {(localStorage.getItem("is_enmovil_transporter") == 1 || window.location.hostname == "3.7.143.97") ?
                                <span>
                                    <img src={require('../../../assets/icons/logo.png')} alt="" />
                                    <span className="logo-caption">Powered by Enmovil &reg;</span>
                                </span>
                                :
                                <span>
                                    {(localStorage.getItem("is_load_management") == 1) ?
                                        <span>
                                            <img src={require('../../../assets/icons/optirun_v2.png')} alt="" />
                                            <span className="logo-caption">Powered by Enmovil &reg;</span>
                                        </span>
                                        :
                                        <span>
                                            <img src={require('../../../assets/icons/logo.png')} alt="" />
                                            <span className="logo-caption">Powered by Enmovil &reg;</span>
                                        </span>
                                    }
                                </span>
                            }
                        </a>
                    </div>
                </div>
                <div className="main-header-right row" style={noPadStyle}>
                    <div className="mobile-sidebar">
                        {/*<div className="media-body text-right switch-sm">
                            <label className="switch">
                                <input type="checkbox" id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar}  />
                                <span className="switch-state"></span>
                            </label>
                        </div>
                        */}
                    </div>
                    <div className="nav-right col">
                        <ul className={"nav-menus " + (this.state.navMenus ? 'open' : '')} >
                            <li style={{ fontSize: "20px" }}>
                                {/* {(localStorage.getItem("loadingPointName") !=undefined) ? 
                                    <span>
                                    {(JSON.parse(localStorage.getItem("loadingPointName"))).length == 1 ?
                                    <span style={{fontSize:"16px"}}>Loading Plant : {(JSON.parse(localStorage.getItem("loadingPointName"))[0].loading_port)}</span>
                                    :
                                    <span style={{fontSize:"16px"}}>Loading Plant : 
                                        <select id="loadingPlant" onChange={this.onChangeLoadingPlant.bind(this)}>
                                            {JSON.parse(localStorage.getItem("loadingPointName")).map(function(obj){
                                                return <option value={obj.loading_port_code}>{obj.loading_port}</option>
                                            })}                                           
                                        </select>
                                    </span>
                                    }
                                    </span>
                                :""} */}
                            </li>
                            {
                            /*((localStorage.getItem('is_load_management') == 1)) ? " " :
                                <li>
                                    <Search />
                                </li>
                            */}
                            {(localStorage.getItem("user_type") == "MARKETING")?
                            <li style={{ fontSize: "14px" }}>Filter By</li>
                            :""}
                            {(localStorage.getItem("user_type") == "MARKETING")?
                                <li style={{width:"290px",textAlign:"left"}}>
                                    <Select 
                                        placeholder={"Select Customer"}
                                        closeMenuOnSelect={true}
                                        onChange={this.changeCustomerData.bind(this)}
                                        className={"border-radius-0 w-200"}
                                        style={{borderRadius:"0px",textAlign:"left"}}
                                        value={this.state.customercode}
                                        options={this.state.customersList} 
                                    /> 
                                </li>
                            :""}
                             {(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0)?
                                <li style={{width:"290px",textAlign:"left"}}>
                                    <Select 
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        onChange={this.changePlantData.bind(this)}
                                        className={"border-radius-0 w-200"}
                                        style={{borderRadius:"0px",textAlign:"left"}}
                                        value={this.state.plantcode}
                                        options={this.state.plantList} 
                                    /> 
                                </li>
                            :""}
                            {(selRoleCheck == 1 || selRoleCheck == "1")?
                                <li style={{width:"290px",textAlign:"left"}}>
                                    <Select 
                                        placeholder={"Select "}
                                        closeMenuOnSelect={true}
                                        onChange={this.changeOperations.bind(this)}
                                        className={"border-radius-0 w-200"}
                                        style={{borderRadius:"0px",textAlign:"left"}}
                                        value={this.state.selectOperation}
                                        options={this.state.selectOptions} 
                                    /> 
                                </li>
                            :""}
                            <li>
                            <div className ="">
                        <img src={require('../../../assets/icons/amns_logo.png')}
                                className="logo-login" alt=""/>
                        </div>
                            </li>
                            <li>
                                <a href="/" className="txt-dec-none clr333">
                                    <i className="icofont icofont-home"></i>
                                </a>
                            </li>

                            {
                            /*((localStorage.getItem('is_load_management') == 1)) ? " " :
                                <li>
                                    <a href="#" className="" onClick={this.changeHandler.bind(this)}>
                                        <i className="icofont icofont-ui-weather" style={{ color: "#000" }}></i>
                                    </a>
                                </li>
                            */}

                            {
                            /*((this.state.loginType != "TRANSPORTER") && (localStorage.getItem('is_load_management') != 1)) ?
                                <li>
                                    <a href="#" className="txt-dec-none clr333" onClick={this.onShowUploadDiv.bind(this)}>
                                        <i className="icofont icofont-support"></i>
                                    </a>
                                </li>
                                : ""
                            */ }

                            {/* <Notification /> */}

                            <UserMenu />
                        </ul>
                        
                        <div className="d-lg-none mobile-toggle" onClick={() => this.toggle()}>
                            <i className="icon-more"></i>
                        </div>
                    </div>
                    
                    {
                    /*(window.location.hostname == "3.7.143.97") ?
                        "" :
                        <div className="">
                            {(localStorage.getItem("is_enmovil_transporter") != 1) ?

                                userType ? "" :
                                    <img src={require('../../../assets/icons/maruti-logo.png')}
                                        className="logo-login" alt="" />


                                : ""}
                        </div>
                    */}

                </div>
                {this.state.sliderRouteTranslate != '' ?
                    <WeatherMapComponent context={this}
                        sliderValue={this.state.sliderRouteTranslate}
                        onCloseRouteDiv={this.onCloseRouteDiv.bind(this)}
                        detailedWeatherMap={this.state.detailedWeatherMap}
                    />
                    : ""
                }
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <a href={"javascript:void(0)"} className={(this.state.tmenu)} id="tmenu">
                    <div id="outer" className={(this.state.outerb)}><div id="inner">&nbsp;</div></div>
                    <div id="outer1" className={(this.state.outerb1)}><div id="sinner">&nbsp;</div></div>
                    <img src={this.state.tpmenu} className={"topmenu " + (this.state.mTop)} defaultChecked={this.state.topbar} onClick={this.openCloseTopbar} alt="" />
                </a>
                {/* { (localStorage.getItem("roles").indexOf("led") >= 0)?"":
                <div className={this.state.showAlert}>
                    <span className="close-menu-alert closebtn" onClick={this.closeMenuAlert.bind(this)} >X</span>
                    <span className="nav-left-arrow"><i className="fa fa-caret-left fa fa-2x"></i></span>
                    <div className="nav-tooltip">
                        <span>Click here to see Menu </span>
                    </div>
                </div>
                
                } */}
                <div className={"slide-r " + (this.state.sliderTranslate)} style={{ width: "55%" }}>
                    <div className="slide-r-title">
                        <h4>
                            DCT Users
                            <span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right " + (this.state.sliderTranslate)}>X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-lg-12">
                            <div id="myGrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefsForDCT}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.departmentData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
$(document).ready(function () {
    $("#sidebar-toggle").click(function () {
        $(".nav-left-arrow").css("display", "none");
        $(".nav-tooltip").css("display", "none");
        $(".close-menu-alert").css("display", "none");
    });
    $(".sidebaropen-icon").click(function () {
        setTimeout(function () {
            $(".nav-left-arrow").css("display", "block");
            $(".nav-tooltip").css("display", "block");
            $(".close-menu-alert").css("display", "block");
        }, 1000)

    });
})

function SortByName(a, b){
    var aName = a.consignee_name.toLowerCase();
    var bName = b.consignee_name.toLowerCase(); 
    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
}
async function parseMenus(menusAll) {
	var groupHeaders = await getGroupHeaderMenus(menusAll);
	//console.log(groupHeaders, "groupheaders")
	groupHeaders.forEach(groupHeader => {
		var groupHeaderChildren  = getChildren(groupHeader.code, menusAll);
		groupHeader.children = groupHeaderChildren;
		groupHeader.children.forEach(groupHeader => {
			groupHeaderChildren  = getChildren(groupHeader.code, menusAll);
			groupHeader.children = groupHeaderChildren;
		});
	});
	return groupHeaders;
}
async function getGroupHeaderMenus(menus) {
	var result = menus.filter(obj => {
	  return obj.is_group_header === true
	})
	//console.log("Matching Group Headers objects = ", result);
	return result;
}
  
function getChildren(parent_code, menus) {
	var result = menus.filter(obj => {
	  if (obj.parent_code === parent_code) {
		return obj;
	  }
	})
	//console.log("Matching objects = ", result);
	return result;
}

export default Header