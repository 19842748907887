import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';

import Map from '../common/drawmap';
import SubmitBtn from './submitBtn';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import Modal from 'react-responsive-modal';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class IncidentReportComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show1: false,
            show: false,
            basicTitle: '',
            basicType: "default",
            oldTruckNo: "",
            truckNumList: "",
            loadshow: 'show-n',
            truckLocation: "",
            incident_Date_time: "",
            transhipment_done_by: "",
            route_details: "",
            rowData: "",
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                SubmitBtn: SubmitBtn
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            route_info: "",
            rowData: null,
            animateRows: true,
            consignment_details: "",
            dept_code: "",
            replacement_truck: "",
            pagetitle: "",
            showonlytransport: 'show-m',
            reason: '',
            driver_name: '',
            driver_mobile: '',
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            usermanualmodal: false,
            consentmodal: false,
            driver_info: "show-n",
            basicType1: "",
            basicTitle1: "",
            err_mobile: "",
            showConsentPopup: 0,
            consentreqParams: "",
            pageType: 1,
            active: "activet",
            closed: "",
            allconsignments: [],
            incidentTypes: [{ label: 'Accident', value: 'Accident' }, { label: 'Break Down', value: 'Break Down' }],
            incident_type: ""
        };
        this.onClickSubmitBtn = this.onClickSubmitBtn.bind(this);

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        loadDateTimeScript();
        $('#replacement-submit').prop("disabled", true);

        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });


        var pagetitle = '';
        if (this.props.match.path == "/prttranshipments") {
            var dept_code = "LOG-PRT";
            pagetitle = "Transshipments (Spare Parts) "
        }
        if (this.props.match.path == "/sndtranshipments") {
            var dept_code = "SNDG"
            pagetitle = "Transshipments"
        }
        if (this.props.match.path == "/tnptranshipments") {
            var dept_code = "LOG-TNP"
            pagetitle = "Transshipments (Train and Production) "
        }
        this.setState({
            loadshow: 'show-m',
            eventAction: eventAction,
            dept_code: dept_code,
            pagetitle: pagetitle
        });
        var params = {
            dept_code: dept_code,
        }
        var useremail = localStorage.getItem("email");
        var urlpath = '/consignments/trucklists';
        if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined") {
            params.plant_code = localStorage.getItem("pc");
        }
        if (localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != "") {
            params.transporter_code = localStorage.getItem("transportercode")
        }
        if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
            params.customer_code = localStorage.getItem("customer_code")
        }
        console.log(params, "paramss")
        redirectURL.post(urlpath, params)
            .then((response) => {
                var consignments = response.data.consignments;
                consignments = consignments.filter(e => e.status == "7" || e.status == 7 || e.status == "8" || e.status == 8)
                console.log(consignments.length,"checkk")
                // var records = consignments.filter(e=>e.incident_type!=""&&e.incident_type!=undefined)
                var truckNumList = [];
                consignments.map(function (param) {
                    truckNumList.push(param.truck_no);
                });
                var logs = consignments.filter(e=>e.incident==1)
                console.log(logs, "logs")
                truckNumList = truckNumList.filter((item, i, ar) => ar.indexOf(item) === i);
                console.log(truckNumList, "truckNumList")
                this.setState({
                    truckNumList: truckNumList,
                    rowData: logs,
                    transhipment_done_by: useremail,
                    allconsignments: consignments
                });

            })
            .catch(function (error) {
                console.log(error);
            });

    }
    // componentWillReceiveProps(newProps){
    //     if(newProps.rowData != this.state.rowData){
    //         this.setState({
    //             rowData : newProps.rowData,
    //         })
    //     }
    // }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }
    selectTruckNum() {
        let arr = [];
        try {
            this.state.truckNumList.forEach(item => {
                //console.log("pod ",item)
                arr.push({ value: item, label: item });
            });
            return arr;
        }
        catch (e) {

        }

    }
    onClickSubmitBtn = (rowdata) => {
        this.setState({ loadshow: 'show-n' })
        var row = rowdata.data
        var incidentEndTime = row.incident_end_time
        var params = {
            incident_end_time: incidentEndTime,
            shipment_id: row.shipment_id
        }
        redirectURL.post("/consignments/saveData", params).then((resp) => {
            if (resp.data.message == "success") {
                this.setState({
                    show: true,
                    basicTitle: "Successfully Updated",
                    basicType: "success",
                })
            }
        })

    }
    onClickFilterReset = () => {
          window.location.reload();

    }
    getTruckConsignments(params) {
        console.log("gettruckconsignmetns");
        // console.log("params",params)
        console.log(params.value);
        try {
            // if(params != null){
            this.setState({
                rowData: [],
                oldTruckNo: params.value
            })
            var trucks = [];
            trucks.push(params.value)
            console.log(trucks);
            var reqParams = {
                truck_no: trucks
            }
            console.log("Trucks", reqParams)
            redirectURL.post("/consignments/getTruckConsignments", reqParams)
                .then((response) => {
                    console.log(response.data);
                    var data = response.data.consignments
                    data = removeDuplicates(data, "shipment_id")
                    this.setState({
                        rowData: data
                    })
                }).catch(function (error) {
                    console.log(error);
                });
            // loadDateTimeScript();
            $('#replacement-submit').prop("disabled", false);
            // }
        }
        catch (e) {
            console.log(e);
        }
    }
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload();
    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    driverChangeHandler = (e) => {
        var driver_changed = $("#driver_changed").val();
        if (driver_changed == 1) {
            $("#driver_mobile").attr("required", true);
            this.setState({
                driver_info: "show-m"
            })
        }
        else {
            $("#driver_mobile").val("");
            $("#driver_mobile").removeAttr("required", true);
            this.setState({
                driver_info: "show-n"
            })
        }
    }
    onChangeIncidentType(incident_type) {
        this.setState({ incident_type }, () => {
        })
    }
    onRowSelection(event) {
        var containerslist = [];
        //console.log("rowselect", event)
        var rwCount = event.api.getSelectedNodes();
        console.log("rwCount ", rwCount)
        //var containerlist=[];
        console.log("rowselect", event.data)
        var rowCount = event.data
        //console.log("rowCount ", rowCount)

        rwCount.map((item) => {
            containerslist.push(item.data.truck_no)
        });

        this.setState({
            containerslist: containerslist
        })
        console.log("checkedlist ", containerslist)

    }
    saveReplacedData(event) {
        event.preventDefault()
        var selectedConsignments = this.state.rowData;
        // var incidenttype =$("#incident_type").val()
        var incident_Date = document.getElementById("incident_Date").value;
        var incidenttype = this.state.incident_type.value
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Save Replacement Data",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        var old_truck_no = this.state.oldTruckNo;
        var truckData = []
        this.gridApi.forEachNode((item) => {
            truckData.push(item.data);
        });
        var params = {
            incident_type: incidenttype,
            incident_Date: incident_Date + ":00",
            selectedConsignments: selectedConsignments
        }
        console.log(params, "366")
        redirectURL.post('/consignments/getshipmentsfortranshipments', params)
            .then((resp) => {
                console.log(resp.data)
                if (resp.data.message == 'success') {
                    this.setState({
                        // show: true,
                        show1: true,
                        basicTitle1: "Successfully",
                        basicType1: "success",
                    })
                }
            })
            // window.location.reload();

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onCloseConsentModal = () => {
        this.setState({ consentmodal: false });
        window.location.reload();
    };
    changeMobileNoHandler(event) {
        var name = event.target.name;
        var value = event.target.value;

        const re = /^[0-9\b]+$/;
        if (isNaN(value) == true) {

        }
        else {
            if (((value === '') || (re.test(value))) && (value.length <= 10)) {

                this.setState({ [name]: value });
            }
            else {

                this.setState({ [name]: value });
            }
        }

    }
    OnBlurMobile(event) {
        var name = event.target.name;
        var value = event.target.value;

        const re = /^[0-9\b]+$/;
        console.log("Value ", ((value === '') + "&&" + (re.test(value))) + "&&" + (value.length < 10));

        if (value == "") {
            this.setState({ err_mobile: "Please enter mobile no", driver_mobile: "" });
        }
        else {
            if ((re.test(value)) && (value.length < 10)) {
                this.setState({ err_mobile: "Invalid Mobile number", driver_mobile: "" });
            }
            else if ((value.length > 10)) {
                this.setState({ err_mobile: "Invalid Mobile number", driver_mobile: "" });
            }
            else {
                this.setState({ err_mobile: "" });
            }
        }

    }

    async onClickTab(pageType) {
        if (pageType == "active") {
            await this.setState({
                pageType: 1,
                active: "activet",
                closed: "",
            });
        }
        else {
            await this.setState({
                pageType: 2,
                active: "",
                closed: "activet",
            });
        }
        // await this.filterData();
    }
    consentAck = (buttonType) => {
        var mobile_no = this.state.consentreqParams.new_driver_mobile;
        var shipment_id = this.state.rowData[0].shipment_id;
        var reqParams = {
            shipment_id: shipment_id,
            mobile_no: mobile_no,
            last_mile_consent_comments: buttonType,
            truck_type_api: "transhipment"
        }
        redirectURL.post("/amns/transhipmentconsentacknowledgement", reqParams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Consent Acknowledgement taken sucessfully.",
                    basicType: "success",
                });
                window.location.reload();
            }).catch(function (error) {
                console.log(error);
            });
    }
    render() {
        const modalStyles = {
            width: '1300px !important',
        }

        const { usermanualmodal } = this.state;
        const { consentmodal } = this.state;
        const columnwithDefs = [
            // {
            //     headerName: "Consignment Code",
            //     field: "consignment_code",
            //     // pinned: 'left',
            //     filter: true,
            //     resizable: true,
            //     // headerCheckboxSelection: true,
            //     // headerCheckboxSelectionFilteredOnly: true,
            //     // checkboxSelection: true
            // },
            {
                headerName: "Truck No",
                width: 100,
                field: "truck_no",
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Shipment Id",
                width: 100,
                field: "shipment_id",
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer Code",
                width: 100,
                field: "consignee_code",
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer Name",
                field: "consignee_name",
                width: 250,
                // pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.consignee_name == "") {
                        return "N/A";
                    }
                    else {
                        return params.data.consignee_name;
                    }
                }
            },
            {
                headerName: "Customer City",
                field: "transporter_zone",
                width: 100,
                // pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.consignee_city == "") {
                        return "N/A";
                    }
                    else {
                        return params.data.consignee_city
                    }
                }
            },
            {
                headerName: "Gateout Time",
                field: "gate_out_time",
                width: 140,
                // pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.loading_yard_exit_time != '' && params.data.loading_yard_exit_time != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time);
                    }
                    else {
                        return "NA";
                    }
                },
            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                width: 140,
                // pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                    }
                    else {
                        return "";
                    }
                },
            },
            {
                headerName: "Incident Type",
                width: 110,
                field: "incident_type",
                // pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Incident Time",
                field: "incident_date",
                width: 140,
                // pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.incident_date != '' && params.data.incident_date != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.incident_date);
                    }
                    else {
                        return "";
                    }
                },
            },
            {
                headerName: 'Incident End Time',
                field: 'incident_end_time',
                width: 140,
                editable: true,
                resizable: true,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    if (params.data.incident_end_time != undefined && params.data.incident_end_time != undefined && params.data.incident_end_time != null) {
                        return getHyphenYYYYMMDDHHMMSS(params.data.incident_end_time)
                    } else {
                        return ""
                    }
                },
                comparator: dateComparator1,

            },
            {
                headerName: "",
                field: "",
                width: 120,
                // pinned: 'right',
                filter: true, resizable: true,
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: 'SubmitBtn'
                    };
                    return rendComponent;
                },
            },

        ]

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                ></SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    <div style={{display:'flex',flex:'auto',justifyContent:'space-between',alignItems:'self-start'}}>
                    <h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Incident Report</span>
                    </h5>

                    {/* <button className="btn btn-info float-right" style={{  }} onClick={this.onClickFilterReset}>
                        Reset Filters
                    </button> */}

                    </div>
                    
                    <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                        <div className="form-group col-xl-3 col-lg-3">
                            <label>Select Truck No </label>
                            <Select
                                placeholder={"Select Truck Number"}
                                closeMenuOnSelect={true}
                                onChange={this.getTruckConsignments.bind(this)}
                                className={"border-radius-0"}
                                style={{ borderRadius: "0px" }}
                                options={this.selectTruckNum()} />
                        </div>
                        {/* <div className="float-right" style={{ marginLeft: '90%' }}>
                            <button onClick={this.onClickFilterReset} className="btn btn-info float-right" style={{ marginTop: "-15px" }}>Reset Filters</button>
                        </div> */}

                    </form>
                </div>
                <div className="col-xl-12 col-lg-12">

                    <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                            enableCharts={true}
                            enableRangeSelection={true}
                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            frameworkComponents={this.state.frameworkComponents}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            //editType={this.state.editType}
                            stopEditingWhenGridLosesFocus={true}
                            //floatingFilter={true}

                            enableCellChangeFlash={false}
                            //suppressCellFlash={true}
                            rowClassRules={this.state.rowClassRules}
                            // onCellDoubleClicked={this.onRowClicked.bind(this)}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            masterDetail={true}
                            // getRowHeight={this.state.getRowHeight}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}

                            rowSelection={this.state.rowSelection}
                            onRowSelected={this.onRowSelection.bind(this)}
                            suppressRowClickSelection={true}
                            // suppressCellSelection= {true}
                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                            excelStyles={this.state.excelStyles}
                        // onCellClicked={this.onCellClicked.bind(this)}
                        />

                    </div>

                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0 mt-20p Transhipment-Edit-Row">
                        <form method="POST" className="row theme-form col-xl-12 col-lg-12" onSubmit={this.saveReplacedData.bind(this)}>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label className="">Incident Truck*</label>
                                <input type="text" class="form-control" name="replacement_truck" id="replacement_truck" onChange={this.changeHandler.bind(this)} autoComplete="off" Placeholder="Truck No." required />
                            </div> */}
                            <div className={"col-xl-3 col-lg-3 form-group"}>
                                <label className="driver_changed">Incident Type*</label>
                                {/* <select name="incident_type" value={this.state.incident_type} autoComplete="off" onChange={this.driverChangeHandler.bind(this)} class="form-control" id="incident_type" required>
                                    <option value="">Select Option</option>
                                    <option value="1">Accident</option>
                                    <option value="2">Break Down</option>
                                </select> */}
                                <Select
                                    required={true}
                                    className=""
                                    name="incident_type"
                                    id="incident_type"
                                    onChange={this.onChangeIncidentType.bind(this)}
                                    value={this.state.incident_type}
                                    options={this.state.incidentTypes}
                                />
                            </div>

                            <div className={"col-xl-3 col-lg-3 form-group "}>
                                <label className="replacement-time">Incident Time*</label>
                                <input type="text" autoComplete="off" class="form-control datetimepicker_mask" id="incident_Date" required />
                            </div>
                            {/* <div className={"col-xl-3 col-lg-3 form-group"}>
                                <label className="reason">Reason*</label>
                                <input type="text" name="reason" value={this.state.reason} autoComplete="off" onChange={this.changeHandler.bind(this)} class="form-control" id="reason" />
                            </div>
                            <div className={"col-xl-3 col-lg-3 form-group"}>
                                <label className="driver_changed">Is Driver Changed*</label>
                                <select name="driver_changed" value={this.state.driver_changed} autoComplete="off" onChange={this.driverChangeHandler.bind(this)} class="form-control" id="driver_changed" required>
                                    <option value="">Select Option</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </select>
                            </div> */}
                            {/* <div className={"col-xl-3 col-lg-3 form-group "+this.state.driver_info}>
                                <label className="driver_name">Driver Name</label>
                                <input type="text" name="driver_name" value={this.state.driver_name} autoComplete="off" onChange={this.changeHandler.bind(this)} class="form-control" id="driver_name" />
                            </div>
                            <div className={"col-xl-3 col-lg-3 form-group "+this.state.driver_info}>
                                <label className="driver_mobile">Driver Mobile*</label>
                                <input type="text" name="driver_mobile" value={this.state.driver_mobile} onBlur={this.OnBlurMobile.bind(this)} autoComplete="off" onChange={this.changeMobileNoHandler.bind(this)} class="form-control" id="driver_mobile" />
								{(this.state.err_mobile != "")?
								<span style={{fontSize:"11px",color:"#ff0000"}}>{this.state.err_mobile}</span>
								:""}
                            </div> */}
                            <div className="col-xl-2 col-lg-2 form-group">
                                {/* <label className="replacement-time"><br /><br /></label> */}
                                <button type="submit" style={{ marginTop: "25px" }} className="btn btn-warning" id="replacement-submit">Submit</button>
                            </div>


                        </form>
                    </div>

                </div>

                <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <h5 className="model-title">Transhipment Screen</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                Transhipment Screen is used to change the primary carrier which has been physically changed by transporters to secondary vehicle because of various reasons like (but not limited to)
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        a. Accident
                                    </li>
                                    <li>
                                        b. Breakdowns
                                    </li>
                                    <li>
                                        c. Geographical Issues
                                    </li>
                                    <li>
                                        d. Administrative issues (caught by local police, transport authorities)
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <h6 className="f16">How to Use:</h6>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        a. Select the vehicle no. which you want to replace with new one.
                                    </li>
                                    <li>
                                        b. Check all the consignments from the list provided which are currently in active mode on vehicle and needs to be transhipped

                                    </li>
                                    <li>
                                        c. Check Gateout time, Invoice time, Consignment code, Consigner code &Consignee detail
                                    </li>
                                    <li>
                                        d. Fill the Replacement Truck no. , Replacement time with Proper Reason
                                    </li>
                                    <li>
                                        e. Click on the submit Button to complete the process of Transhipment in system
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <h6 className="f16">Consignment details:</h6>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                All the data of consignment in Transhipment screen we can arrange it as per our requirement by using “columns” option. Some important column inside are – Consignment code, Invoice Time, Gateout Time & Consignee detail(Code, Name, City) Also we can filterout each of these column.
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal open={consentmodal} onClose={this.onCloseConsentModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <h5 className="model-title">Consent Acknowledgement</h5>
                        <div className="f20" style={{ margin: "2% 0 0 3%" }}>
                            <ul className="d-tabs">
                                <li onClick={this.onClickTab.bind(this, "active")} className={"lttabs " + (this.state.active)}>
                                    <button type="button" className="btn">English</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this, "closed")} className={"lttabs " + (this.state.closed)}>
                                    <button type="button" className="btn">Hindi</button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                {(this.state.pageType == 1) ?
                                    <iframe src={require('../../assets/images/SIM_Consent_Process_english.pdf')} width="100%" height="350px"></iframe>
                                    : ""}
                                {(this.state.pageType == 2) ?
                                    <iframe src={require('../../assets/images/SIM_Consent_Process_hindi.pdf')} width="100%" height="350px"></iframe>
                                    : ""}
                                <h5>Did you completed your consent process?</h5>
                                <button onClick={this.consentAck.bind(this, "yes")} className='btn btn-primary'>Yes</button>
                                <button onClick={this.consentAck.bind(this, "no")} className='btn btn-danger'>No</button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}

function removeDuplicates(array, variableName) {
    var seenValues = {};
    return array.filter(function (item) {
        var value = item[variableName];
        if (!seenValues.hasOwnProperty(value)) {
            seenValues[value] = true;
            return true;
        }
        return false;
    });
}

window.onload = function () {
    // $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
    // });
    $('.datetimepicker_mask').datetimepicker({
        mask: '9999-19-39 29:59',
        format: 'Y-m-d H:i',
        // minDate:1
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};

function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        // mask:'9999-19-39 29:59',
        format: 'Y-m-d H:i',
        // minDate:new Date()
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        //console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function monthToComparableNumber1(date) {
    //console.log(date.length);

    //console.log(date);
    if (date === undefined || date === null || date.length !== 10) {

        return null;

    }

    var yearNumber = date.substring(6, 10);

    var monthNumber = date.substring(3, 5);

    var dayNumber = date.substring(0, 2);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

    return result;

}
function DateEditor() { }
// gets called once before the renderer is used

DateEditor.prototype.init = function (params) {

    // create the cell

    console.log(params, "params")

    this.eInput = document.createElement('input');

    this.eInput.value = params.value;
    // https://jqueryui.com/datepicker/

    $(this.eInput).datetimepicker({

        dateFormat: "yy-mm-dd",

        changeMonth: true,

        changeYear: true

    });

};




// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function () {

    return this.eInput;

};




// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function () {

    this.eInput.focus();

    this.eInput.select();

};




// returns the new value after editing

DateEditor.prototype.getValue = function () {

    return this.eInput.value;

};




// any cleanup we need to be done here

DateEditor.prototype.destroy = function () {

    // but this example is simple, no cleanup, we could

    // even leave this method out as it's optional

};




// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function () {

    // and we could leave this method out also, false is the default

    return false;

};