import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from './mlldrawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import EditMLLConsignment from "./editrailsconsignment";
import CountUp from 'react-countup';
import MLLConsignmentsChildGrid from "./railconsignmentlegs";
import ShowRoute from "./showtptroute";
import ShowTPTRoute1 from "./showtptroute1"
import ShowTPTRoute2 from "./ShowTPTRoute2"

import ShowEtaLegs from "./showtptetalegs";
import ShipmentLegs from "./shipmentlegs";
import LegRouteMap from "./legroutemap";
import LoadingAreaLegs from "./loadingarealegs";
import CreatableSelect from 'react-select/creatable';


$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var Constant = require('../common/Constant');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class AMNSRailConsignments extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			orginalRecords : [],
			transporterOptions : [],
			selectedTransporter : {value:"all",label:"ALL",},
			detailCellRendererParams:{},
            frameworkComponents: {
				editMLLConsignment:EditMLLConsignment,
				showroute:ShowRoute,
				ShowTPTRoute1:ShowTPTRoute1,
				ShowTPTRoute2:ShowTPTRoute2,
				showetalegs:ShowEtaLegs,
                ShipmentLegs:ShipmentLegs,
                LegRouteMap:LegRouteMap,
                LoadingAreaLegs:LoadingAreaLegs,
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					// {
					// id: "filters",
					// labelDefault: "Filters",
					// labelKey: "filters",
					// iconKey: "filter",
					// toolPanel: "agFiltersToolPanel"
					// }
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"",
			sliderRouteTranslate:'',
			sliderForceDestination:"",
			sliderForceDestination1:"",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
			sliderRakeTranslate1:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
			sourceopt:'',
			fnr_no:"",
			gpsdevice:"",
			sources:[],
			totalvinsarr:[],
			totalvins:0,
			atplantarr:[],
			atplant:0,
			intransitloadarr:[],
			intransitload:0,
			atloadarr:[],
			atload:0,
			unloadingatloadingyardarr:[],
			unloadingatloadingyard:0,
			rakeassignarr:[],
			rakeassign:0,
			uniqueRakes:0,
			intransitdestarr:[],
			intransitdest:0,
			atdestinationarr:[],
			atdestination:0,
			unloadingatdestinationyardarr:[],
			unloadingatdestinationyard:0,
			intransitdealerarr:[],
			intransitdealer:0,
			nearcutomerarr:[],
			nearcutomer:0,
			intransitrsoarr:[],
			intransitrso:0,
			atrsoarr:[],
			atrso:0,
			neardealerarr:[],
			neardealer:0,
			tripendrsoarr:[],
			tripendrso:0,
			podreceiptarr:[],
			podreceipt:0,
			unloadatloadingarr:[],
			unloadatloading:0,
			startrailloadingarr:[],
			startrailloading:0,
			unloadvehiclesarr:[],
			unloadvehicles:0,
			lastmiledestination:"",
			desttype:"",
			destypeopt:"",
			consignees:[],
			rsos:[],
			destopts:[],
			sliderForceLastMile:"",
			sliderRso:"",
			modetype:"",
			dropdownOpen: false,
			dropdownAssetOpen: false,
			sliderLoadingYardTranslate: "",
			destinations:[],
			unloadofficers:[],
			loading_officer:"",
			batransporter:"",
			transporters:[],
			devices:[],
			sliderDeviceRetrival:"",
			retrived:"",
			retrivalon:"",
			retrivedyes:"",
			retrivedno:"",
			showaction:"show-n",
			mapinfo:[],
			sliderDealerArrival:"",
			vehicles:[],
			gpsdeviceids:[],
			mapfirstmile:[],
			mapmiddlemile:[],
			maplastmilerso:[],
			maplastmiledealr:[],
			destopt:"",
			routefor:"",
			fmile:[],
			mmile:[],
			ulmile:[],
			rsomile:[],
			refno:"",
			rail_no:"",
			confirmdeliversarr:[],
			confirmdelivers:0,
			livelocation:"",
			liveaddress:"",
			liveactiveon:"",
			tosfile:"",
			verifytos:0,
			rake_no:"default",
			rakeList:({value:"",label:""}),
			railStatus:8,
			unloading_at_destination:0,
			firstMile:[],
			middleMile:[],
			lastMile:[],
			firstMileData:[],
			middleMileData:[],
			lastMileData:[],
			totalvins_quantity:0,
			intransitload_quantity:  0,
			unloadingatloadingyard_quantity:  0,
			rakeassign_quantity:  0,
			atdestination_quantity:  0,
			unloadingatdestinationyard_quantity:  0,
			intransitdealer_quantity:  0,
			nearcutomer_quantity: 0,
			neardealer_quantity:  0,
        };
        // this.editRakeData = this.editRakeData.bind(this);
        // this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onClickShowMap = this.onClickShowMap.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.changeFileHandler1 = this.changeFileHandler1.bind(this);
		this.changeDestFileHandler = this.changeDestFileHandler.bind(this);
		this.changeDestFileHandler1 = this.changeDestFileHandler1.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.onClickEditBlock = this.onClickEditBlock.bind(this);
		this.changeLastMileFileHandler = this.changeLastMileFileHandler.bind(this);
		this.onChangeLastMilDestinationItem = this.onChangeLastMilDestinationItem.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleAsset = this.toggleAsset.bind(this);
		this.checkRadioEvent = this.checkRadioEvent.bind(this);
		this.onClickShowMapRoute = this.onClickShowMapRoute.bind(this);
		this.onClickShowETALegs = this.onClickShowETALegs.bind(this);
		this.changeTOSFileHandler = this.changeTOSFileHandler.bind(this);
        this.onClickShowShipmentLegs = this.onClickShowShipmentLegs.bind(this);
        this.onClickShowLoadingAreaLegs = this.onClickShowLoadingAreaLegs.bind(this);
	}
	
    componentDidMount(){
		loadDateTimeScript();
		var rake_no = this.props.match.params.rake_no;
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
		this.setState({
			showaction:"show-n"
		})
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate,
			rake_no:rake_no
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
        var dpt='SNDG';        
        this.setState({
			dept_code:dpt,
		});		
		var tquery = {}
		redirectURL.post("/dashboard/tptfirstmileretrivedevices", tquery)
		.then((response) => {
			var transporters = response.data.transporters;
			this.setState({
				transporters:transporters,
			});
		})
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
		var parameters = {
			startdate:sdate,
			enddate:edate
		}
        this.onLoadRakePlanningData(parameters)		
	}
    
    onLoadRakePlanningData(parameters)
    {
		this.setState({
			loadshow:"show-m",
			overly:"show-m",
		})
		var rake_no = this.props.match.params.rake_no;
		var transporter_code = localStorage.getItem("transportercode")
		var prms = {
			transporter_code:transporter_code
		}
		if(rake_no != "default")
		{
			parameters.rake_no = rake_no;
		}
	   	try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				parameters.plant_code = localStorage.getItem("pc");
			}
		}catch(e){
			
		}
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			parameters.transporter_code = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			parameters.customer_code = localStorage.getItem("customer_code")
		}
		redirectURL.post("/dashboard/tptconsignments",parameters)
		.then((response) => {
			var records = response.data.records;
			
			var totalvinsarr=[];
			var totalvins=0;
			var atplantarr=[];
			var atplant=0;
			var intransitloadarr=[];
			var intransitload=0;
			var atloadarr=[];
			var atload=0;
			var rakeassignarr=[];
			var rakeassign=0;
			var uniqueRakes=0;
			var intransitdestarr=[];
			var intransitdest=0;
			var atdestinationarr=[];
			var unloadingatdestinationyardarr=[];
			var atdestination=0;
			var intransitdealerarr=[];
			var intransitdealer=0;
			var nearcutomerarr=[];
			var nearcutomer=0;
			var intransitrsoarr=[];
			var intransitrso=0;
			var atrsoarr=[];
			var atrso=0;
			var neardealerarr=[];
			var neardealer=0;
			var tripendrsoarr = [];
			var tripendrso = 0;
			var podreceiptarr=[];
			var podreceipt=0;
			var vehicles = [];
			var unloadatloadingarr = [];
			var startrailloadarr = [];
			var unloadvehiclesarr = [];
			var confirmdeliversarr = [];
			var verifytos = [];
			//var rakeList = [];
			var rakeDataList = [];
			var unloadingatloadingyardarr = [];
			var startrailloadingarr = [];
			var unloadingatloadingyard = 0;
			if(records.length > 0)
			{
				records.map((item) => {
					vehicles.push({vin_no:item.vehicle_id});
					if(parseInt(item.status) == 1 || parseInt(item.status) == 2 || parseInt(item.status) == 3 || parseInt(item.status) == 4 || parseInt(item.status) == 5 || parseInt(item.status) == 7)
					{
						atplantarr.push(item)
					}
					if(parseInt(item.status) == 8)
					{
						intransitloadarr.push(item)
					}
					if(parseInt(item.status) == 9)
					{
						if(item.unloading_at_loading_yard == 1)
						{
							unloadingatloadingyardarr.push(item);
						}
						else
						{
							intransitloadarr.push(item)
						}
					}
					if(parseInt(item.status) == 9 && item.loading_yard_reached_on != "")
					{
						atloadarr.push(item)
						verifytos.push(item);
						if(item.start_rail_loading == 1)
						{
							startrailloadingarr.push(item);
						}
					}
					if(parseInt(item.status) == 10 || parseInt(item.status) == 11)
					{
						rakeassignarr.push(item)
						verifytos.push(item);
					}
					if(parseInt(item.status) == 11)
					{
						intransitdestarr.push(item)
					}
					if(parseInt(item.status) == 12)
					{
						if(item.unloading_at_destination_yard == 1)
						{
							unloadingatdestinationyardarr.push(item)
						}
						else
						{
							atdestinationarr.push(item)
						}
					}
					if(parseInt(item.status) == 13)
					{
						intransitdealerarr.push(item)
					}
					if(parseInt(item.status) == 14)
					{
						nearcutomerarr.push(item)
					}
					if(parseInt(item.status) == 15)
					{
						neardealerarr.push(item)
					}
					if(parseInt(item.status) == 16)
					{
						confirmdeliversarr.push(item)
					}
				})
			}
			// console.log(records.length,"check_records")
			var firstMile = records.filter((r)=>r.shipment_mode == "RAIL" && r.status >= 8 && r.status <= 9);
			var fmData = groupBy(firstMile, rdata => rdata.truck_no);
			var firstMileCount = [];
			fmData.forEach((fData, truckNo) => {
				firstMileCount.push(truckNo)
			})

			var firstMile = response.data.records.filter((r)=>r.shipment_mode == "RAIL" && r.status >= 8 && r.status <= 9);
			// var fmData = groupByMultiKeys(firstMile, [rdata => rdata.truck_no,rdata => rdata.invoice_time]);
			// var fmData = distinctArrayByWithMultipleKeys(firstMile,"truck_no","shipment_id");
			var fmData = groupBy(firstMile, rdata => rdata.truck_no);
			var firstMileCount = [];
			fmData.forEach((fData, truckNo) => {
				firstMileCount.push(truckNo)
			})
			// console.log(firstMile.length,"firstMile Length")
			var middleMileData = records.filter((r)=>r.shipment_mode == "RAIL" && r.status >= 10 && r.status <= 12);
			var rakeData = groupBy(middleMileData, rdata => rdata.fnr_no);
			var middileMileCount = [];
			rakeData.forEach((rData, fnr_no) => {
				middileMileCount.push(fnr_no)
			})
			var lastMileRakes = records.filter((r)=>r.shipment_mode == "RAIL" && r.status >= 13);
			var lastMileTruckData = groupBy(lastMileRakes, rdata => rdata.last_mile_truck_no);
			var lastMileCount = [];
			lastMileTruckData.forEach((tData, truckNo) => {
				lastMileCount.push(truckNo)
			})
			var lastMileData = lastMileRakes;
			
			var uniqueTransporterValues = new Set(records.map(item => item.transporter_name));
			uniqueTransporterValues = [...uniqueTransporterValues];
			var transporterOptions = [{value:"all",label:"ALL"}]
			uniqueTransporterValues.map(item => {
				transporterOptions.push({value:item,label:item}) 
			})
			var totalvins_quantity = records.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0)
			var intransitload_quantity = intransitloadarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0)
			if(rakeassignarr.length > 0)
			{
			    var uniqueRakesData = groupBy(rakeassignarr, rdata => rdata.fnr_no);
				var uniqueRakesCount = [];
				uniqueRakesData.forEach((tData, fnr_no) => {
					uniqueRakesCount.push(fnr_no)
				})
				uniqueRakes = uniqueRakesCount.length;
			}
			this.setState({
				rowData:records,
				orginalRecords:response.data.records,
				transporterOptions:transporterOptions,
				totalvinsarr:records,
				totalvins:records.length,
				atplantarr:atplantarr,
				atplant:atplantarr.length,
				intransitloadarr:intransitloadarr,
				intransitload:intransitloadarr.length,
				atloadarr:atloadarr,
				atload:atloadarr.length,
				unloadingatloadingyardarr:unloadingatloadingyardarr,
				unloadingatloadingyard:unloadingatloadingyardarr.length,
				startrailloadingarr:startrailloadingarr,
				startrailloading:startrailloadingarr.length,
				rakeassignarr:rakeassignarr,
				rakeassign:rakeassignarr.length,
				uniqueRakes:uniqueRakes,
				intransitdestarr:intransitdestarr,
				intransitdest:intransitdestarr.length,
				atdestinationarr:atdestinationarr,
				atdestination:atdestinationarr.length,
				unloadingatdestinationyardarr:unloadingatdestinationyardarr,
				unloadingatdestinationyard:unloadingatdestinationyardarr.length,
				intransitdealerarr:intransitdealerarr,
				intransitdealer:intransitdealerarr.length,
				nearcutomerarr:nearcutomerarr,
				nearcutomer:nearcutomerarr.length,
				intransitrsoarr:intransitrsoarr,
				intransitrso:intransitrsoarr.length,
				atrsoarr:atrsoarr,
				atrso:atrsoarr.length,
				neardealerarr:neardealerarr,
				neardealer:neardealerarr.length,
				podreceiptarr:podreceiptarr,
				podreceipt:podreceiptarr.length,
				tripendrsoarr:tripendrsoarr,
				tripendrso:tripendrsoarr.length,
				vehicles:vehicles,
				unloadatloading:unloadatloadingarr.length,
				unloadatloadingarr:unloadatloadingarr,
				unloadvehicles:unloadvehiclesarr.length,
				unloadvehiclesarr:unloadvehiclesarr,
				startrailloading:startrailloadarr.length,
				startrailloadingarr:startrailloadarr,
				confirmdeliversarr:confirmdeliversarr,
				confirmdelivers:confirmdeliversarr.length,
				verifytos:verifytos.length,
				loadshow:"show-n",
				overly:"show-n",
				firstMile:firstMile,
				firstMileData:firstMileCount.length,
				middleMileData:middleMileData,
				middleMile:middileMileCount.length,
				lastMileData:lastMileData,
				lastMile:lastMileCount.length,
				totalvins_quantity: records.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				intransitload_quantity : intransitloadarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				unloadingatloadingyard_quantity : unloadingatloadingyardarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				rakeassign_quantity: rakeassignarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				atdestination_quantity : atdestinationarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				unloadingatdestinationyard_quantity : unloadingatdestinationyardarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				intransitdealer_quantity: intransitdealerarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				nearcutomer_quantity : nearcutomerarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				neardealer_quantity : neardealerarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),

			});			
			this.onClickCounter("intransitload")
		})
     
    }
    
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='Consignments';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			try{
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
				
			}
			catch(e){

			}
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowatLoadingyard =(params)=>{
		this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate1:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
	}
	onClickShowUploadTOSSidebar = (params) => {
		this.setState({
            //forceclosedata:params.data,
            sliderUploadTOSTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
	}

	onClickShowLoadingYardSidebar(){
		this.setState({
            sliderLoadingYardTranslate:"slider-translate-60p",
            overly:'show-m',
        })
	}
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
			sliderRakeTranslate1:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderForceDestination1:'',
			sliderBulkForceClose:"",
			sliderForceLastMile:"",
			sliderRso:"",
			sliderLoadingYardTranslate: "",
			sliderDeviceRetrival:"",
			sliderDealerArrival:"",
            sliderUploadTOSTranslate:'',
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
	handleChange = (item) => {
        let rakeNo = item
		this.setState({ rake_no: rakeNo })
        // if (rakeNo !== null && rakeNo !== "") {
        //     let rake_no = rakeNo.value;
        //     // if (rakeNo.__isNew__ == true) {
        //     //     rake_no = rakeNo.value.toUpperCase()
        //     //     this.setState({rake_no:{value:rake_no,label:rake_no}})
        //     // }else{
        //     //     this.setState({rake_no:item})
        //     // }
        //     // console.log("rake_no", rake_no)
        //     this.setState({ rake_no: item })
        // }else{
        //     this.setState({
        //         rake_no : ""
        //     })
        // }
    };
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
	rakeList(){
		
		let items = [];
		console.log(this.state.rakeList,"this.state.rakeList")    
		try{
			if(this.state.rakeList.length > 0)
			{
				this.state.rakeList.map((item) =>{
					items.push({"value":item,"label":item})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    selectdestinations(){
		let items = [];    
		try{
			if(this.state.destinations.length > 0)
			{
				this.state.destinations.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
        
    gpsdevices(){
		let items = [];
		var devices = this.state.devices;
		if(devices.length > 0)
		{
			devices.map((item) => {
				items.push({"value":item.device_code,"label":item.device_code})
			})
		}    
		return items;
	}
    
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeDestinationItem(destopt){
		this.setState(
			{ destopt },
			() => console.log(`Option selected:`, this.state.destopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                   // this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    onClickShowView(view)
    {
       // console.log("View ",view)
        if(view == "grid")
        {
            this.setState({
                gridview:'show-m',
                calcview:'show-n'
            })
        }
        if(view == "calender")
        {
            this.setState({
                gridview:'show-n',
                calcview:'show-m',
                calview:"timeGridWeek"
            })
        }
    }
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			sliderRakeTranslate1:"",
			silderUploadTOSTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			sliderDealerArrival:"",
		});
	}
	onClickShowMap = (params) =>{
       
    }

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

     handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;	
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;	
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }
	changeFileHandler1 = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vehicleNo',
					inputName: 'vehicleNo',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'lrNumber',
					inputName: 'lrNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'invoiceNumber',
					inputName: 'invoiceNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'batchNumber',
					inputName: 'batchNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'date',
					inputName: 'date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'time',
					inputName: 'time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'rail_siding',
					inputName: 'rail_siding',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}

	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'rakeNumber',
					inputName: 'rakeNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'fnrNumber',
					inputName: 'fnrNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'invoiceNumber',
					inputName: 'invoiceNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'batchNumber',
					inputName: 'batchNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'date',
					inputName: 'date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'time',
					inputName: 'time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'rail_siding',
					inputName: 'rail_siding',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'destination_rail_siding',
					inputName: 'destination_rail_siding',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}
	changeDestFileHandler1 = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vehicleNo',
					inputName: 'vehicleNo',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'invoiceNumber',
					inputName: 'invoiceNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'batchNumber',
					inputName: 'batchNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'date',
					inputName: 'date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'time',
					inputName: 'time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'driverName',
					inputName: 'driverName',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'driverMobileNo',
					inputName: 'driverMobileNo',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'lastMileLRNo',
					inputName: 'lastMileLRNo',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}
	
	changeDestFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'rakeNumber',
					inputName: 'rakeNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'fnrNumber',
					inputName: 'fnrNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'invoiceNumber',
					inputName: 'invoiceNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'batchNumber',
					inputName: 'batchNumber',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'date',
					inputName: 'date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'time',
					inputName: 'time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'rail_siding',
					inputName: 'rail_siding',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }
	formUnloadingatLoadingYard = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Rake Planning Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
		var userid=localStorage.getItem("userid")
		var	username=localStorage.getItem("username")
		var	useremail=localStorage.getItem("email")
       var csvcontent = this.state.csvcontent
		csvcontent.map((e)=>{
				e.timestamp = e.date+" "+e.time
		})
	   console.log(csvcontent,"csvcontent 1376")
	   var params  = {
		csvcontent : csvcontent,
		userid : userid,
		username: username,
		email: useremail,
	   }
	   console.log(params,"line 1581")
	   redirectURL.post("/dashboard/tptconsignmentsbulkupdatenew",params).then((response)=>{
		if(response.data.status == "Success" && response.data.notvalid.length == 0){
			this.setState({
				sliderRakeTranslate1:"",
				overly:'show-n',
				show:true,
				basicTitle:"All batch items have been successfully updated.",
				basicType:"success"
			})
		}
		else
		{
			if( response.data.notvalid.length > 0)
			{
				this.setState({
					show:true,
					basicTitle:response.data.resonForFailure,
					basicType:"danger"
				})
			}
			else
			{
				this.setState({
					show:true,
					basicTitle:"Failed to update items",
					basicType:"danger"
				})
			}
		}
	   })
    }

    formRakeSchedule = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Rake Planning Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var csvcontent = this.state.csvcontent
		csvcontent.map((e)=>{
			e.timestamp = e.date+" "+e.time
		})
		// console.log("csvcontent ", this.state.csvcontent);
		var fnr_no = this.state.fnr_no;
		var rake_no = "";
		var rakeNo = this.state.rake_no;
		if(rakeNo !=undefined && rakeNo !="")
		{
			if(rakeNo.value !="" && rakeNo.value !=undefined)
			{
				rake_no = rakeNo.value;
			}
		}
		if(this.state.gpsdevice != "")
		{
			var gpsdevice = this.state.gpsdevice.value
        }
		else
		{
			var gpsdevice = ""
		}
		var gpsdeviceids = []
		if(this.state.gpsdeviceids.length > 0)
		{
			
			this.state.gpsdeviceids.map((item) => {
				gpsdeviceids.push(item.value)
			})
		}
		// console.log("gpsdeviceids ", gpsdeviceids)
		var dest_yard_reachedon = $("#dest_yard_reachedon").val()
		// console.log("dest_yard_reachedon ", dest_yard_reachedon)
		// var transporter_code  = this.state.batransporter.value;
		// var transporter_name  = this.state.batransporter.label;

		if(this.state.loading_officer != "")
		{
			var loading_officer_code =  this.state.loading_officer.value;
			var loading_officer_name =  this.state.loading_officer.label;
	
		}
		else
		{
			var loading_officer_code =  "";
			var loading_officer_name =  "";
		}
		
		
		if(this.state.sourceopt != "")
		{
			var source =  this.state.sourceopt.value;
			var source_name =  this.state.sourceopt.label;
	
		}
		else
		{
			var source =  "";
			var source_name =  "";
		}
		
		if(this.state.destopt != "")
		{
			var destination = this.state.destopt.value;
			var destination_name =this.state.destopt.label;
		}
		else
		{
			var destination = "";
			var destination_name ="";
		}
			var reqparams = {
				refno:this.state.refno,
				csvcontent:csvcontent,
				source:source,
				source_name:source_name,
				destination:destination,
				destination_name:destination_name,
				fnr_no:fnr_no,
				rake_no:rake_no,
				gpsdevice:gpsdeviceids,
				dest_yard_reachedon:dest_yard_reachedon,
				unloading_officer_code:loading_officer_code,
                unloading_officer_name:loading_officer_name,
                transporter_code:localStorage.getItem("transportercode"),
				// transporter_code:transporter_code,
				// transporter_name:transporter_name,
				userid:localStorage.getItem("userid"),
				username:localStorage.getItem("username"),
				useremail:localStorage.getItem("email")
			}
			console.log("reqparams linr 1726", reqparams)
						redirectURL.post("/dashboard/updaterakeschedulevinsnew", reqparams)
						.then((response) => {
							if(response.data.status == "success" && response.data.notvalid.length == 0)
							{
								$("#dest_yard_reachedon").val("")
								this.setState({
									sliderRakeTranslate:"",
									overly:'show-n',
									sourceopt:"",
									gpsdevice:"",
									csvcontent:[],
									fnr_no:"",
									loadshow:'show-n',
									uploadFile:'',
									file:'',
									show:true,
									basicTitle:"All batch items have been successfully updated.",
									basicType:"success"
								});
								var parameters = {
									startdate:this.state.startDate,
									enddate:this.state.endDate,
									refno:this.state.refno
								}
								this.onLoadRakePlanningData(parameters)
							}
							else
							{
								if( response.data.notvalid.length > 0)
								{
									this.setState({
										show:true,
										basicTitle:response.data.resonForFailure,
										basicType:"danger"
									})
								}
								else
								{
									this.setState({
										show:true,
										basicTitle:"Failed to update items",
										basicType:"danger"
									})
								}
								
							}
						
						})
    }

	
    formUploadTOS = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Upload TOS Dummy Trucks",
				"action": "form",
				"label": "Upload TOS Dummy Trucks",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
		var reqparams = new FormData()
			reqparams.append("file", this.state.tosfile)
			reqparams.append("userid", localStorage.getItem("userid"))
			reqparams.append("username", localStorage.getItem("username"))
			reqparams.append("useremail", localStorage.getItem("email"))
			//console.log("reqparams ", reqparams)
			redirectURL.post("/dashboard/readtosdata", reqparams)
			.then(async (response) => {
				//console.log("TOS", response.data)
				var records = response.data.records;
				var vins = []
				var emptyvins = []
				if(records.length > 0)
				{
					await records.map((item) => {
						Object.keys(item).forEach(async function(key) {
							// console.log("VEHICLE ID ", key)
							if(key == "VIN")
							{
								vins.push(item[key])
								//console.log("item[key] ", item[key])
								
							}
							
						})
						// console.log("prpery ", item.hasOwnProperty("VEHICLE ID"))
						if(item.hasOwnProperty("VIN"))
						{
						
						}
						else{
							emptyvins.push(1)
						}
					})
					
				}
				if(emptyvins.length > 0)
				{
					this.setState({
						show:true,
						basicTitle:"Upload data should not be empty",
						basicType:"danger"
					})
				}
				else
				{
					var params = {
						"xlsdata": records,
						"vins" : vins,
						"userid" : localStorage.getItem("userid"),
						"username": localStorage.getItem("username"),
						"useremail": localStorage.getItem("email"),
						"transporter_code": localStorage.getItem("transportercode"),
						"refno" : this.state.refno
					}
					redirectURL.post("/dashboard/uploadtosdata", params)
					.then((resp) => {
						console.log("data ",resp.data)
						if(resp.data.message == "vins")
						{
							this.setState({
								show:true,
								basicTitle:"VINs are not exists",
								basicType:"danger"
							})
						}
						else if(resp.data.message == "vinsempty")
						{
							this.setState({
								show:true,
								basicTitle:"VIN is empty in uploaded file",
								basicType:"danger"
							})
						}
						else
						{
							this.setState({
								sliderUploadTOSTranslate:"",
								overly:'show-n',
								transporter_code:"",
								transporter_name:"",
								csvcontent:[],
								loadshow:'show-n',
								uploadFile:'',
								tosfile:'',
								showrel:true,
								basicTitlerel:"Successfully upload file",
								basicTyperel:"success"
							})
							
						}
					})
				}
			})
	}
	
    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
    
    onClickEditBlock(params){
        console.log("params ",params)
	}
    
    onChangeDeviceItem(gpsdeviceids){
		this.setState(
			{ gpsdeviceids },
			() => {
				if(gpsdeviceids.length > 0)
				{
					if(gpsdeviceids.length > 2)
					{
						this.setState({
							show1:true,
							basicTitle1:"Maximum 2 devices are allowed",
							basicType1:"info",
							gpsdeviceids:[]
						})
					}	
				}
			}
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onClickLastMileTruck(){
		this.setState({
			sliderForceDestination1:'slider-translate',
			overly:"show-m"
		});
	}
	onClickForceMoveDestinationYard(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceDestination:'slider-translate',
			overly:"show-m"
		});
	}
	
	formLastMileTransport = async (event) =>{
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Upload TOS Dummy Trucks",
				"action": "form",
				"label": "Upload TOS Dummy Trucks",
			}
			googleAnalytics.logEvent(eventOptions);
        }
		var csvcontent = this.state.csvcontent
		csvcontent.map((e)=>{
			e.timestamp = e.date+" "+e.time
		})
			var reqparams = {
				//refno:this.state.refno,
				userid:localStorage.getItem('userid'),
				username:localStorage.getItem('username'),
				email:localStorage.getItem('email'),
                csvcontent:csvcontent,
                //transporter_code:localStorage.getItem("transportercode")
			}
			console.log(reqparams,"reqparams")
			
					redirectURL.post("/dashboard/tptlasmileupdatenew", reqparams)
					.then((response) => {
						// console.log("Respone ", response.data)
						var rec = response.data;
						if(rec.notvalid.length == 0)
						{
							this.setState({
								show: true, 
								basicType:'success', 
								basicTitle:"All batch items have been successfully updated.",
								uploadDivWidth:'0%',
								sliderForceDestination1:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate,
								refno:this.state.refno
							}
							this.onLoadRakePlanningData(parameters)
						}
						else if(rec.notvalid.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:rec.resonForFailure,
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						else{
							
							
							
						}
						
					})
	}
	
	formForceMoveDestinationYardHandler = async (event) =>{
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Upload TOS Dummy Trucks",
				"action": "form",
				"label": "Upload TOS Dummy Trucks",
			}
			googleAnalytics.logEvent(eventOptions);
        }
		var csvcontent = this.state.csvcontent
		csvcontent.map((e)=>{
			e.timestamp = e.date+" "+e.time
		})
			var reqparams = {
				//refno:this.state.refno,
				userid:localStorage.getItem('userid'),
				username:localStorage.getItem('username'),
				email:localStorage.getItem('email'),
                csvcontent:csvcontent,
                //transporter_code:localStorage.getItem("transportercode")
			}
			console.log(reqparams,"reqparams")
			
					redirectURL.post("/dashboard/tptmovedestinationyardarrivalnew", reqparams)
					.then((response) => {
						// console.log("Respone ", response.data)
						var rec = response.data;
						if(rec.notvalid.length == 0 && rec.notexists.length == 0)
						{
							this.setState({
								show: true, 
								basicType:'success', 
								basicTitle:"All batch items have been successfully updated.",
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate,
								refno:this.state.refno
							}
							this.onLoadRakePlanningData(parameters)
						}
						else if(rec.notvalid.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:rec.resonForFailure,
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						else if(rec.notexists.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:rec.notexists+" are not exists",
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						else{
							
							
							
						}
						
					})	
	}
	
    onClickCounter(data)
	{
		var status =""
		if(data == "all")
		{
			this.setState({
				rowData:this.state.totalvinsarr
			})
		}
		// if(data == "atplant")
		// {
		// 	this.setState({
		// 		rowData:this.state.atplantarr,
		// 		status:7,
		// 	})
		// }
		if(data == "intransitload")
		{
			status=8;
			this.setState({
				rowData:this.state.intransitloadarr,
				railStatus : status,
			})
		}
		// if(data == "atload")
		// {
		// 	this.setState({
		// 		rowData:this.state.atloadarr,
		// 		status:9,
		// 	})
		// }
		if(data == "rakeassign")
		{
			status=10;
			// var rowData = [...this.state.rakeassignarr, ...this.state.intransitdestarr];
			this.setState({
				rowData:this.state.rakeassignarr,
				unloading_at_destination:0,
				railStatus : status,
			})
		}
		// if(data == "intransitdest")
		// {
		// 	this.setState({
		// 		rowData:this.state.intransitdestarr,
		// 		status:11,
		// 	})
		// }
		if(data == "atdestination")
		{
			status=12;
			this.setState({
				rowData:this.state.atdestinationarr,
				unloading_at_destination:0,
				railStatus : status,
			})
		}
		if(data == "unloadingatdestinationyard")
		{
			status=12;
			this.setState({
				rowData:this.state.unloadingatdestinationyardarr,
				unloading_at_destination:1,
				railStatus : status,
			})
		}
		// if(data == "intransitrso")
		// {
		// 	this.setState({
		// 		rowData:this.state.intransitrsoarr
		// 	})
		// }
		// if(data == "atrso")
		// {
		// 	this.setState({
		// 		rowData:this.state.atrsoarr
		// 	})
		// }
		if(data == "intransitdealer")
		{
			status = 13;
			this.setState({
				rowData:this.state.intransitdealerarr,
				unloading_at_destination:0,
				railStatus : status,
			})
		}
		if(data == "neardealer")
		{
			this.setState({
				rowData:this.state.neardealerarr
			})
		}
		// if(data == "podreceipt")
		// {
		// 	this.setState({
		// 		rowData:this.state.podreceiptarr
		// 	})
		// }
		// if(data == "tripendrso")
		// {
		// 	this.setState({
		// 		rowData:this.state.tripendrsoarr
		// 	})
		// }
		// 
		
		// if(data == "unloadatload")
		// {
		// 	this.setState({
		// 		rowData:this.state.unloadatloadingarr
		// 	})
		// }
		
		// if(data == "startrail")
		// {
		// 	this.setState({
		// 		rowData:this.state.startrailloadingarr
		// 	})
		// }
		
		// if(data == "unloadvehicles")
		// {
		// 	this.setState({
		// 		rowData:this.state.unloadvehiclesarr
		// 	})
		// }
		
		if(data == "delivered")
		{
			this.setState({
				rowData:this.state.confirmdeliversarr
			})
		}
		
		if(data == "nearcutomer")
		{
			this.setState({
				rowData:this.state.nearcutomerarr
			})
		}
		
		if(data == "unloadingatloadingyard")
		{
			status = 9;
			this.setState({
				rowData:this.state.unloadingatloadingyardarr,
				unloading_at_destination:0,
				railStatus : status,
			})
		}
		if(data == "firstMile")
		{
			this.setState({
				rowData:this.state.firstMile
			})
		}
		if(data == "middleMile")
		{
			this.setState({
				rowData:this.state.middleMileData
			})
		}
		if(data == "lastMile")
		{
			this.setState({
				rowData:this.state.lastMileData
			})
		}
	}

	onchangeTransporterType = (event) => {
		console.log(event.value)
		this.setState({
			selectedTransporter : event
		})
	}


	counterData = (records) => {
		var totalvinsarr=[];
			var totalvins=0;
			var atplantarr=[];
			var atplant=0;
			var intransitloadarr=[];
			var intransitload=0;
			var atloadarr=[];
			var atload=0;
			var rakeassignarr=[];
			var rakeassign=0;
			var uniqueRakes=0;
			var intransitdestarr=[];
			var intransitdest=0;
			var atdestinationarr=[];
			var unloadingatdestinationyardarr=[];
			var atdestination=0;
			var intransitdealerarr=[];
			var intransitdealer=0;
			var nearcutomerarr=[];
			var nearcutomer=0;
			var intransitrsoarr=[];
			var intransitrso=0;
			var atrsoarr=[];
			var atrso=0;
			var neardealerarr=[];
			var neardealer=0;
			var tripendrsoarr = [];
			var tripendrso = 0;
			var podreceiptarr=[];
			var podreceipt=0;
			var vehicles = [];
			var unloadatloadingarr = [];
			var startrailloadarr = [];
			var unloadvehiclesarr = [];
			var confirmdeliversarr = [];
			var verifytos = [];
			//var rakeList = [];
			var rakeDataList = [];
			var unloadingatloadingyardarr = [];
			var startrailloadingarr = [];
			var unloadingatloadingyard = 0;
			if(records.length > 0)
			{
				records.map((item) => {
					// if(item.rake_no !=undefined && item.rake_no !="")
					// {
					// 	if (rakeDataList.indexOf(item.rake_no) === -1)
					// 	{
					// 		rakeDataList.push(item.rake_no);
					// 		rakeList.push({value:item.rake_no,name:item.rake_no});
					// 	}
					// }
					vehicles.push({vin_no:item.vehicle_id});
					if(parseInt(item.status) == 1 || parseInt(item.status) == 2 || parseInt(item.status) == 3 || parseInt(item.status) == 4 || parseInt(item.status) == 5 || parseInt(item.status) == 7)
					{
						atplantarr.push(item)
					}
					if(parseInt(item.status) == 8)
					{
						intransitloadarr.push(item)
					}
					if(parseInt(item.status) == 9)
					{
						if(item.unloading_at_loading_yard == 1)
						{
							unloadingatloadingyardarr.push(item);
						}
						else
						{
							intransitloadarr.push(item)
						}
					}
					if(parseInt(item.status) == 9 && item.loading_yard_reached_on != "")
					{
						atloadarr.push(item)
						verifytos.push(item);
						// if(item.unloading_at_loading_yard == 1)
						// {
						// 	unloadingatloadingyardarr.push(item);
						// }
						if(item.start_rail_loading == 1)
						{
							startrailloadingarr.push(item);
						}
					}
					if(parseInt(item.status) == 10 || parseInt(item.status) == 11)
					{
						rakeassignarr.push(item)
						verifytos.push(item);
					}
					if(parseInt(item.status) == 11)
					{
						intransitdestarr.push(item)
					}
					if(parseInt(item.status) == 12)
					{
						if(item.unloading_at_destination_yard == 1)
						{
							unloadingatdestinationyardarr.push(item)
						}
						else
						{
							atdestinationarr.push(item)
						}
					}

					// if(parseInt(item.status) == 7)
					// {
					// 	intransitrsoarr.push(item)
					// }
					// if(parseInt(item.status) == 8)
					// {
					// 	atrsoarr.push(item)
					// }
					if(parseInt(item.status) == 13)
					{
						intransitdealerarr.push(item)
					}
					if(parseInt(item.status) == 14)
					{
						nearcutomerarr.push(item)
					}
					if(parseInt(item.status) == 15)
					{
						neardealerarr.push(item)
					}
					// if(parseInt(item.status) == 12)
					// {
					// 	podreceiptarr.push(item)
					// }
					// if(parseInt(item.status) == 14)
					// {
					// 	tripendrsoarr.push(item)
					// }
					
					// if(parseInt(item.status) == 11)
					// {
					// 	unloadvehiclesarr.push(item)
					// }
					
					// if(parseInt(item.status) == 14)
					// {
					// 	unloadatloadingarr.push(item)
					// 	verifytos.push(item);
					// }
					
					// if(parseInt(item.status) == 15)
					// {
					// 	startrailloadarr.push(item)
					// 	verifytos.push(item);
					// }
					if(parseInt(item.status) == 16)
					{
						confirmdeliversarr.push(item)
					}
				})
			}
			var firstMile = records.filter((r)=>r.shipment_mode == "RAIL" && r.status >= 8 && r.status <= 9);
			var fmData = groupBy(firstMile, rdata => rdata.truck_no);
			var firstMileCount = [];
			fmData.forEach((fData, truckNo) => {
				firstMileCount.push(truckNo)
			})
			var middleMileData = records.filter((r)=>r.shipment_mode == "RAIL" && r.status >= 10 && r.status <= 12);
			var rakeData = groupBy(middleMileData, rdata => rdata.fnr_no);
			var middileMileCount = [];
			rakeData.forEach((rData, fnr_no) => {
				middileMileCount.push(fnr_no)
			})
			var lastMileRakes = records.filter((r)=>r.shipment_mode == "RAIL" && r.status >= 13);
			var lastMileTruckData = groupBy(lastMileRakes, rdata => rdata.last_mile_truck_no);
			var lastMileCount = [];
			lastMileTruckData.forEach((tData, truckNo) => {
				lastMileCount.push(truckNo)
			})
			var lastMileData = lastMileRakes;
			
			var uniqueTransporterValues = new Set(records.map(item => item.transporter_name));
			uniqueTransporterValues = [...uniqueTransporterValues];
			var transporterOptions = [{value:"all",label:"ALL"}]
			uniqueTransporterValues.map(item => {
				transporterOptions.push({value:item,label:item}) 
			})
			console.log(uniqueTransporterValues,"checks123")
			
			if(rakeassignarr.length > 0)
			{
			    var uniqueRakesData = groupBy(rakeassignarr, rdata => rdata.fnr_no);
				var uniqueRakesCount = [];
				uniqueRakesData.forEach((tData, fnr_no) => {
					uniqueRakesCount.push(fnr_no)
				})
				uniqueRakes = uniqueRakesCount.length;
			}
			this.setState({
				rowData:records,
				totalvinsarr:records,
				totalvins:records.length,
				totalvins_quantity: records.reduce((acc, obj) => acc + obj.item_quantity, 0),
				atplantarr:atplantarr,
				atplant:atplantarr.length,
				intransitloadarr:intransitloadarr,
				intransitload:intransitloadarr.length,
				atloadarr:atloadarr,
				atload:atloadarr.length,
				unloadingatloadingyardarr:unloadingatloadingyardarr,
				unloadingatloadingyard:unloadingatloadingyardarr.length,
				startrailloadingarr:startrailloadingarr,
				startrailloading:startrailloadingarr.length,
				rakeassignarr:rakeassignarr,
				rakeassign:rakeassignarr.length,
				uniqueRakes:0,
				intransitdestarr:intransitdestarr,
				intransitdest:intransitdestarr.length,
				atdestinationarr:atdestinationarr,
				atdestination:atdestinationarr.length,
				unloadingatdestinationyardarr:unloadingatdestinationyardarr,
				unloadingatdestinationyard:unloadingatdestinationyardarr.length,
				intransitdealerarr:intransitdealerarr,
				intransitdealer:intransitdealerarr.length,
				nearcutomerarr:nearcutomerarr,
				nearcutomer:nearcutomerarr.length,
				intransitrsoarr:intransitrsoarr,
				intransitrso:intransitrsoarr.length,
				atrsoarr:atrsoarr,
				atrso:atrsoarr.length,
				neardealerarr:neardealerarr,
				neardealer:neardealerarr.length,
				podreceiptarr:podreceiptarr,
				podreceipt:podreceiptarr.length,
				tripendrsoarr:tripendrsoarr,
				tripendrso:tripendrsoarr.length,
				vehicles:vehicles,
				unloadatloading:unloadatloadingarr.length,
				unloadatloadingarr:unloadatloadingarr,
				unloadvehicles:unloadvehiclesarr.length,
				unloadvehiclesarr:unloadvehiclesarr,
				startrailloading:startrailloadarr.length,
				startrailloadingarr:startrailloadarr,
				confirmdeliversarr:confirmdeliversarr,
				confirmdelivers:confirmdeliversarr.length,
				verifytos:verifytos.length,
				loadshow:"show-n",
				overly:"show-n",
				firstMile:firstMile,
				firstMileData:firstMileCount.length,
				middleMileData:middleMileData,
				middleMile:middileMileCount.length,
				lastMileData:lastMileData,
				lastMile:lastMileCount.length,
				totalvins_quantity: records.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				intransitload_quantity : intransitloadarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				unloadingatloadingyard_quantity : unloadingatloadingyardarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				rakeassign_quantity: rakeassignarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				atdestination_quantity : atdestinationarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				unloadingatdestinationyard_quantity : unloadingatdestinationyardarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				intransitdealer_quantity: intransitdealerarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				nearcutomer_quantity : nearcutomerarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
				neardealer_quantity : neardealerarr.reduce((acc,obj)=>acc+parseFloat(obj.item_quantity) , 0).toFixed(2),
			});			
			this.onClickCounter("intransitload")
	}

	onSubmitTransporter = async (event) => {
		event.preventDefault()
		console.log(this.state.transporterOptions,this.state.selectedTransporter,"1729")
		if(this.state.selectedTransporter.value != "all"){
			var records = this.state.orginalRecords.filter(item => item.transporter_name == this.state.selectedTransporter.value)
			await this.setState({
				rowData:records
			})
			this.counterData(records)
			
		}
		else{
			await this.setState({
				rowData:this.state.orginalRecords
			})
			this.counterData(this.state.orginalRecords)

		}
	}
	
	onClickLastMileTransport(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceLastMile:'slider-translate',
			overly:"show-m"
		});
	}
	
	changeLastMileFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		// var out = new Promise(function(reject, resolve){
		// 	var reader =  new FileReader();
		// 	reader.onload = async function(e) {
		// 		var contents = await e.target.result;
		// 		console.log("contents ", contents )
		// 		resolve(contents);
		// 	};
		// 	var tt =  reader.readAsText(e.target.files[0]);
		// 	console.log("tt ",tt)
		// });
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

	onChangeLastMilDestinationItem(lastmiledestination){
		this.setState(
			{ lastmiledestination },
			async () => {
				var items = []
				// console.log("lastmiledestination.value ", lastmiledestination.value)
				if(lastmiledestination.value != "")
				{
					var desttype = "";
					// console.log("lastmiledestination.value ",this.state.rsos)
					if(lastmiledestination.value == "RSO")
					{
						var rso = this.state.rsos;
						if(rso.length > 0)
						{
							rso.map((item) => {
								items.push({
									value:item.plant_code,
									label:item.plant_name
								});
							})
						}
						desttype = "Select RSO";
					}
					if(lastmiledestination.value == "Dealer")
					{
						var consignees = this.state.consignees;
						// console.log("consignees", consignees)
						if(consignees.length > 0)
						{
							consignees.map((item) => {
								items.push({
									value:item.consignee_code,
									label:item.consignee_name
								});
							})
						}
						
						// console.log("consignees items ", items)
						desttype = "Select Dealer";
					}
					
				}
				// console.log("items ", items)
				await this.setState({
					destopts:items,
					desttype:desttype
				})
			}
          );
		  
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:'',
			//   destopts:items
		  })
	}

	onChangeDestType(destypeopt){
		this.setState(
			{ destypeopt },
			() => console.log(`Option selected:`, this.state.destypeopt)
		  );
		  
		 
	}
	onChangeModeType(modetype){
		this.setState(
			{ modetype },
			() => console.log(`Option selected:`, this.state.modetype)
		  );
	}
	desttype(){
		var items = []
		if(this.state.destypeopt != "")
		{
			if(this.state.destypeopt.value == "RSO")
			{
				var rso = this.state.rsos;
				if(rso.length > 0)
				{
					rso.map((item) => {
						item.push({
							value:item.rso,
							label:item.rso
						});
					})
				}
			}
			if(this.state.destypeopt.value == "Dealer")
			{
				var consignees = this.state.consignees;
				if(consignees.length > 0)
				{
					consignees.map((item) => {
						item.push({
							value:item.consignee_code,
							label:item.consignee_name
						});
					})
				}
			}
		}
		return items;
	}

	formLastMileData(event)
	{
		event.preventDefault();
		var destypeopt = this.state.destypeopt.value;
		var destypeoptname = this.state.destypeopt.label;
		var lastmiledestination = this.state.lastmiledestination.value;
		var csvcontent = this.state.csvcontent;
		var modetype = this.state.modetype.value;
		var parameters = {
			destypeopt:destypeopt,
			destypeoptname:destypeoptname,
			lastmiledestination:lastmiledestination,
			modetype:modetype,
			csvcontent:csvcontent,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		// console.log("parameters ", parameters)

		redirectURL.post("/dashboard/verifytptlastmile",{csvcontent:this.state.csvcontent})
		.then((response) => {
			// console.log("resposnedata ", response.data)
			if(response.data.records.length == 0)
			{
				this.setState({
					show:true,
					basicTitle:"Uploaded Vins destination not exists",
					basicType:"danger"
				});
			}
			else
			{
				
				redirectURL.post("/dashboard/tptlasmileupdate", parameters)
				.then((response) => {
					// console.log("Respone ", response.data)
					var rec = response.data;
					if(rec.notvalid.length == 0)
					{
						this.setState({
							show: true, 
							basicType:'success', 
							basicTitle:"Successfully updated.",
							uploadDivWidth:'0%',
							sliderForceLastMile:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:'',
							csvcontent:""
						});
						var parameters = {
							startdate:this.state.startDate,
							enddate:this.state.endDate,
							refno:this.state.refno
						}
						this.onLoadRakePlanningData(parameters)
					}
					else if(rec.notvalid.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:"Destination yard not exists. ",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else if(rec.notexists.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:rec.notexists+" are not exists",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else{
						
						
						
					}
					
				})
			}
		})
	}

	onClickRSOData(){
		this.setState({
			sliderRso:'slider-translate',
			overly:'show-m',
		})
	}

	formRSOData(event){
		event.preventDefault();
		var rsoreachedon = $("#rso_reached").val();
		console.log("rsoreachedon ", rsoreachedon)
		if(rsoreachedon != "" && rsoreachedon != "__-__-____ __:__" && rsoreachedon != undefined)
		{
			var csvcontent = this.state.csvcontent;
			var parameters = {
				rsoreachedon:rsoreachedon,
                csvcontent:csvcontent,
                transporter_code:localStorage.getItem("transportercode"),
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username"),
				userid:localStorage.getItem("userid")
			}
			// console.log("parameters ", parameters)
	
			redirectURL.post("/dashboard/verifymllrso",{csvcontent:this.state.csvcontent})
			.then((response) => {
				// console.log("resposnedata ", response.data)
				if(response.data.status  == "nodata")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid data uploaded",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Uploaded Vins are not rake assigned",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "success")
				{				
					redirectURL.post("/dashboard/mllrsoupdate", parameters)
					.then((response) => {
						// console.log("Respone ", response.data)
						var rec = response.data;
						$("#uploadFile").val("")
						if(rec.notvalid.length == 0)
						{
							$("#rso_reached").val("");
							this.setState({
								show: true, 
								basicType:'success', 
								basicTitle:"Successfully updated.",
								uploadDivWidth:'0%',
								sliderRso:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:'',
								csvcontent:""
							});
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate,
								refno:this.state.refno
							}
							this.onLoadRakePlanningData(parameters)
						}
						else if(rec.notvalid.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:"In Transit RSO not exists. ",
								uploadDivWidth:'0%',
								sliderRso:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						
						else{
							
							
							
						}
						
					})
				}
				else
				{
					this.setState({
						show:true,
						basicTitle:"Failed to update data",
						basicType:"danger"
					});
				}
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Please select data and time",
				basicType:"danger"
			});
		}
		
	}
	
	toggle() {

        this.setState(prevState => ({

            dropdownOpen: !prevState.dropdownOpen

        }));

	}
	
	toggleAsset() {

        this.setState(prevState => ({

            dropdownAssetOpen: !prevState.dropdownAssetOpen

        }));

	}
	transporters()
	{
		let items = [];
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.transporter_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	loadingofficers()
	{
		let items = [];
		try{
			if(this.state.unloadofficers.length > 0)
			{
				this.state.unloadofficers.map((item) =>{
					items.push({"value":item.load_officer_code,"label":item.load_officer_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	
	onChangeBA(batransporter){
		this.setState(
			{ batransporter },
			() => console.log(`Option selected:`, this.state.batransporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeLoadingOfficer(loading_officer){
		this.setState(
			{ loading_officer },
			() => console.log(`Option selected:`, this.state.loading_officer)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	
	onClickGPSDeviceRetrival(){
		this.setState({
			sliderDeviceRetrival:'slider-translate',
			overly:'show-m',
		})
	}

	formDeviceRetrival(event){
		event.preventDefault();
		var retrivalon = $("#retrivalon").val();
		var retrived = ""
		$(".retrivecls").each(function(){
			if($(this).is(":checked") == true)
			{
				retrived = $(this).val();
			}
		})
		if(this.state.gpsdevice != "")
		{
			var gpsdeviceid = this.state.gpsdevice.value
		}
		else{
			var gpsdeviceid = ""
		}
		var parameters = {
			retrivalon:retrivalon,
			retrived:retrived,
			gpsdeviceid:gpsdeviceid,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		console.log("parameters ", parameters)

					
		redirectURL.post("/dashboard/mllretrived", parameters)
		.then((response) => {
			// console.log("Respone ", response.data)
			var rec = response.data;
			if(rec.status == "success")
			{
				document.getElementById("retrivalon").value = "";
				this.setState({
					show: true, 
					basicType:'success', 
					basicTitle:"Successfully updated.",
					sliderDeviceRetrival:'',
					overly:'show-n',
					loadshow:'show-n',
					gpsdevice:""
				});
				$(".retrivecls").each(function(){
					if($(this).is(":checked") == true)
					{
						retrived = $(this).prop("checked", false);
					}
				})

			}
			else
			{
				this.setState({
					show: true, 
					basicType:'danger', 
					basicTitle:"Failed to update. ",
					sliderDeviceRetrival:'',
					overly:'show-n',
					loadshow:'show-n'
				});
			}
			
			
		})
			
	}

	
    checkRadioEvent = (event) => {
        var boolValue = event.target.value;

        if (typeof event.target.value == "string" && event.target.name != "env_show") {
            boolValue = (event.target.value.toLowerCase() === 'true');
            
        }

        if ( event.target.name == "is_group_header") {
            let displayGroupHeader = (boolValue) ? "show-m" : "show-n";
            this.setState({groupheader:displayGroupHeader});
        }
        
    	let name = event.target.name;
		//let value = event.target.value;
        this.setState({[name]:boolValue});
        if (this.state.updateData) {
            let newUpdateData = this.state.updateData;
            newUpdateData[name] = boolValue;
            this.setState({updateData:newUpdateData});
        }
    }
	

	onClickShowMapRoute(rownode)
	{
		this.setState({
			loadshow:'show-m'
		})
		// console.log("Rownode ", rownode)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		
		var truck_no = rownode.data.truck_no;
		var status = parseInt(rownode.data.status);
		if(status <= 12)
		{
			var cdate = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
		}
		else
		{
			var cdate = moment.parseZone(rownode.data.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
		}
		if(rownode.data.parking_in_time != "")
		{
			var fdate = moment.parseZone(rownode.data.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
		}
		else
		{
			var fdate = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
		var parameters = {
			truck_no: truck_no,
			from_date: fdate,
			to_date: cdate,
			consignment_code: rownode.data.consignment_code,
		}
		redirectURL.post("/dashboard/mllmaproute",parameters)
		.then((response) => {
			console.log("response ", response.data)
			// console.log("records ", response.data.records)
			if(response.data.status == "success")
			{
				var mp = response.data;
				
				// var phases = mp.phase_details;
				var mapfirstmile =[]
				var mapmiddlemile =[]
				var maplastmilerso =[]
				var maplastmiledealr =[]
				var breaks = []
				/*if(phases.length > 0)
				{
					phases.map((item) => {
						if(item.breaks.length > 0)
						{
							var allbreaks = item.breaks;
							allbreaks.map((brk) => {
								breaks.push(brk);
							})
						}
						if(item.set == "1")
						{
							mapfirstmile.push(item)
						}
						if(item.set == "2")
						{
							mapmiddlemile.push(item)
						}
						if(item.set == "3" && parseInt(item.directly_to_dealer) == 0)
						{
							maplastmilerso.push(item)
						}
						
						if(item.set == "3" && parseInt(item.directly_to_dealer) == 1)
						{
							maplastmiledealr.push(item)
						}
						try{
							if(item.set == "4")
							{
								maplastmiledealr.push(item)
							}
						}
						catch(e){

						}
						
					})
				}*/
				// console.log("breaks ", breaks)
				var mpdata = {}
				mpdata.coords = mp.coords;
				mpdata.route_details = mp.route_details
				mpdata.additional_details = mp.additional_details
				mpdata.breaks = breaks
				this.setState({
					overly:'show-m',
					loadshow:'show-n',
					sliderRouteTranslate:"slider-translate-98p",
					mapinfo:mpdata,
					routefor:rownode.data.vehicle_id,
					mapfirstmile:mapfirstmile,
					mapmiddlemile:mapmiddlemile,
					maplastmilerso:maplastmilerso,
					maplastmiledealr:maplastmiledealr,
					map_type_of_tracking :rownode.data.type_of_tracking_cons
				})
				// console.log(rownode.data.type_of_tracking_cons,"type of tracking crons")
			}
			else
			{
				this.setState({
					loadshow:'show-n',
					show1:true,
					basicTitle1:"No data found",
					basicType1:"danger"
				})
			}
		})
		
		
	}

	
	onClickDealerArrival(){
		this.setState({
			sliderDealerArrival:'slider-translate',
			overly:"show-m"
		});
	}
	
	formDealerData(event){
		event.preventDefault();
		var dealer_reached = $("#dealer_reached").val();
		var csvcontent = this.state.csvcontent;
		var dealer_reached = document.getElementById("dealer_reached").value;
		if(dealer_reached != "" && dealer_reached != "__-__-____ __:__")
		{
			var parameters = {
				dealer_reached_on:dealer_reached,
				csvcontent:csvcontent,
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username"),
				userid:localStorage.getItem("userid")
			}
			// console.log("parameters ", parameters)

			redirectURL.post("/dashboard/verifymlldealer",{csvcontent:this.state.csvcontent})
			.then(async (response) => {
				console.log("resposnedata ", response.data)
				if(response.data.status == "empty")
				{
					this.setState({
						show:true,
						basicTitle:"No Data found in uploaded file",
						basicType:"danger"
					});
				}
				else if(response.data.status == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid Vins",
						basicType:"danger"
					});
				}
				else
				{
					var recs = response.data.records;
					var vehicles = this.state.vehicles;
					if(recs.length > 0)
					{
						// var notacceptvins =await recs.filter(f =>
						// 	!vehicles.some(d => d.vin_no == f.vin_no)
						// );
						// if(notacceptvins.length > 0)
						// {
						// 	this.setState({
						// 		show:true,
						// 		basicTitle:"Uploaded vehicle ids are not exists",
						// 		basicType:"danger"
						// 	});
						// }
						// else
						// {
							redirectURL.post("/dashboard/mlldealerupdate", parameters)
							.then((response) => {
								// console.log("Respone ", response.data)
								var rec = response.data;
								$("#uploadFile").val("")
								
								$("#dealer_reached").val("");
								this.setState({
									show: true, 
									basicType:'success', 
									basicTitle:"Successfully updated.",
									uploadDivWidth:'0%',
									sliderDealerArrival:'',
									overly:'show-n',
									uploadFile:'',
									loadshow:'show-n',
									file:'',
									csvcontent:""
								});
								
							})
						// }
					}
					else
					{
						this.setState({
							show:true,
							basicTitle:"No Data found in uploaded file",
							basicType:"danger"
						});
					}
				}
				
				
			})
		}
		else
			{
				this.setState({
					show:true,
					basicTitle:"Please select date and time",
					basicType:"danger"
				});
			}
	}


	

	onClickShowETALegs = async(params) =>{

		console.log("Rownode ", params)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		await this.setState({
						
			detailCellRendererParams:{
				suppressRefresh: true,
				detailGridOptions: {
					headerHeight:50,
					columnDefs: [
						{
							headerName:"Source",
							width:450,
							resizable:true,
							field:"source_station",
						},
						{
							headerName:"Destination",
							width:200,
							resizable:true,
							field:"destination_station",
						},
						{
							headerName:"Recent Location Status",
							width:400,
							resizable:true,
							field:"last_destination_report",
						},	
						{
							headerName:"Destination ETA",
							width:150,
							resizable:true,
							field:"destinatiion_eta",
							valueGetter:function(params){
								try{
									if(params.data.destinatiion_eta != "" && params.data.destinatiion_eta != undefined)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.destinatiion_eta);
									}
									else{
										return "";
									}
								}
								catch(e){}
							}
						},
					],
					overlayNoRowsTemplate: 'No rows to show',						
				},
				getDetailRowData:async function(param) {
					param.successCallback([]);
					// console.log("Step 4 ",param)
					// console.log("reqparams",reqparams);
					await redirectURL.post("/dashboard/getFnrTransitLogs",{
						fnr_no : param.data.fnr_no
					}).then(async (response) =>{
						//console.log("Step 1 ",response.data)
						// console.log(response.data.data);
						var comments=response.data;
						param.successCallback(comments);
						
						
						//console.log("Step 2 ",response.data)
						
					}).catch(function(error){
						console.log(error);
					})
					 
				},
			}
		});
		
		//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
		if(params.column.colDef.field == 'vehicle_id')
		{
			params.node.setExpanded(!params.node.expanded);
			
		}
		else{

			params.node.setExpanded(false);
		}
		
	}
	changeTOSFileHandler = async (e) => {
		
		this.setState({
			tosfile:e.target.files[0]
		});
		
		
	}
	onClickShowShipmentLegs= async (rownode) =>  {
        // console.log("rownode", rownode)
        var commentHeaders = [
            {
				headerName : "",
				field : "",
				resizable : true,
				width:60,
				filter : true,
                cellRendererFramework:LegRouteMap,
			  },
			{
				headerName : Constant.COL_TRUCK_NO,
				field : "truck_no",
				resizable : true,
				width:120,
				filter : true
			  },
			//   {
			// 	headerName : Constant.COL_CONSIGNMENT_CODE,
			// 	field : "consignment_code",
			// 	resizable : true,
			// 	width:140,
			// 	filter : true
			//   },
			  {
				headerName : Constant.COL_LOADING_AREA_NAME,
				field : "fence_name",
				resizable : true,
				width:200,
				filter : true
			  },
			  {
				headerName : Constant.COL_LEGT_START_TIME,
				field : "first_inside_fence",
				resizable : true,
				width:160,
				filter : true,
                valueGetter:function(params){
                    try{
                        if(params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_LEGT_END_TIME,
				field : "first_outside_fence",
				resizable : true,
				width:160,
				filter : true,
                valueGetter:function(params){
                    try{
                        if(params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_TIME_SPEND,
				field : "time_spent",
				resizable : true,
				width:200,
				filter : true,
                valueGetter:function(params){
                    try{
                        
                        return params.data.time_spent;
                        
                    }
                    catch(e){}
                }
			  },

        ]
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Params ", param);
                    if(param.data.tpa_entry_time != "" && param.data.tpa_entry_time != undefined){
                        var startdate = param.data.tpa_entry_time;
                    }
                    else{
                        var startdate = param.data.parking_in_time;
                    }
                    var cdate = moment.parseZone().format("YYYY-MM-DD HH:mm");
                    var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;

                    if(param.data.status == "10" || param.data.status == "11" || param.data.status == 10 || param.data.status == 11)
					{
						var enddate = param.data.recent_dealer_reported;
					}
					else{
						if(param.data.status == 6 || param.data.status == "6")
						{
							var enddate = param.data.force_closure_time;
						}
						else{
							var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
						}
						
					}
                    var parameters = {
                        consignment_code : param.data.consignment_code,
                        truck_no : param.data.truck_no,
                        startdate:startdate,
                        enddate:enddate
                    }
                    await redirectURL.post("/amns/loadingarealegsdata",parameters).then(async (response) =>{
                        //console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var comments=response.data;
                        var output=[]
                        if(comments.length > 0){
                            comments.map((item) => {
                                try{
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        var ste = item.first_inside_fence;
                                        var stsec = moment.parseZone(ste).format("x");
                                        item.orderpos = stsec;
                                    }
                                    if(item.first_outside_fence != "" && item.first_outside_fence != undefined){
                                        var etsec = moment.parseZone(item.first_outside_fence).format("x")
                                    }
                                    else{
                                        var etsec = moment.parseZone().format("x");
                                    }
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        // console.log("etsec ", etsec)
                                        // console.log("stsec ", stsec)
                                        var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                                        var timespent = secondsToDhms(diff);
                                    }
                                    else{
                                        var timespent = "";
                                    }
                                    item.time_spent = timespent;
                                        
                                }
                                catch(e){

                                }
                                console.log("item ", item)
                                output.push(item);
                            })
                        }
                        output.sort(GetSortASCOrder("orderpos"));
                        param.successCallback(output);
                        
                        
                        //console.log("Step 2 ",response.data)
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'shipmentlegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
	onClickShowLoadingAreaLegs = async (rownode) =>  {
        console.log("rownode", rownode)
        var commentHeaders = [        
            {
                headerName : Constant.COL_CONSIGNMENT_CODE,
                field : "consignment_code",
                resizable : true,
                width:140,
                filter : true
            },
            {
                headerName : Constant.COL_STATUS,
                field : "status",
                resizable : true,
                width:140,
                filter : true,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
					if(params.data.status == 1)
					{
						return Constant.INSIDE_TPA;
					}
					else if(params.data.status == 2)
					{
						return Constant.INSIDE_MY_PMILL;
					}
					
					if(params.data.status == 1 || params.data.status == "1")
					{
						return Constant.INSIDE_TPA;
					}
					if(params.data.status == 2 || params.data.status == "2")
					{
						return  Constant.INTRANSIT_TO_MY;
					}
					if(params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5")
					{
						return  Constant.INSIDE_MY_PMILL;
					}			
					else if(params.data.status == 6)
					{
						return Constant.FORCE_CLOSE;
					}
					else if(params.data.status == 7)
					{
						return Constant.INSIDE_TPA_AFTER_LOADING;
					}
					else if(params.data.status == 8 )
					{
						return Constant.INTRANSIT;
					}
					else if(params.data.status == 9 )
					{
						return Constant.NEAR_CUSTOMER;
					}
					else if(params.data.status == 10 )
					{
						return Constant.REACHED_CUSTOMER;
					}
					else if(params.data.status == 11 )
					{
						return Constant.LEFT_CUSTOMER;
					}
					else{
						return ""
					}
				}
            },
            {
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                resizable : true,
                filter : true,
                width:120,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                resizable : true,
                filter : true,
                width:200,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                resizable : true,
                filter : true,
                width:120,
            },
            
            {
                headerName:Constant.COL_CUSTOMER_NAME,
                field:"consignee_name",
                resizable : true,
                filter : true,
                width:200,
            },
            {
                headerName:Constant.COL_CUSTOMER_CODE,
                field:"consignee_code",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.COL_CUSTOMER_CITY,
                field:"transporter_zone",
                resizable : true,
                filter : true,
                width:140,
            },
            {
                headerName:Constant.COL_EXPECTED_DISTANCE_KM,
                field:"distance_in_km",
                resizable : true,
                filter : true,
                width:140,
            },

        ]
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    if(param.data != "" && param.data != undefined)
                    {
                        try{
                            // var output = JSON.parse(param.data.leg_details);
                            var output = [param.data];
                            console.log("output ", output)
                            param.successCallback(output);
                        }
                        catch(e){}
                    }
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'loadingarealegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
    render(){
		var isNotSideAdmin = true; // By Default user is not an admin
		var userAdminRole = localStorage.getItem('roles') || [] // Retrieves the user roles ,if not assigns an empty array
		if(userAdminRole && userAdminRole.includes("SITE_ADMIN")){ //If roles is there and it includes "SITE_ADMIN" Enters into the if block
			var isNotSideAdmin = false 	// Indicates that it is "SITE_ADMIN" is true
		}
		var hideForTransporter="";
		if(localStorage.getItem("user_type")=="TRANSPORTER"){
			hideForTransporter=true;
		}
		else{
			hideForTransporter=false;
		}
		var columnwithDefs = [];
		if(localStorage.getItem("user_type") != "TRANSPORTER")
		{
			columnwithDefs.push(
				{
					headerName:"",
					field:"consignment_code",
					width:50,
					resizable:true,
					pinned:"left",
					filter:true,
					cellRendererSelector:function(params){
						if(params.data.is_mm_coords_available==1||params.data.is_mm_coords_available=="1"){
							var rendComponent = {
								component: 'ShowTPTRoute1'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'ShowTPTRoute2'
							};
							return rendComponent
						}
						
					
					},
					hide:hideForTransporter,
					suppressColumnsToolPanel:hideForTransporter,
					
				},
				// {
				// 	headerName:"",
				// 	field:"vehicle_id",
				// 	width:50,
				// 	resizable:true,
				// 	pinned:"left",
				// 	cellRendererSelector:function(params){
				// 		var rendComponent = {
				// 			component: 'showetalegs'
				// 		};
				// 		return rendComponent
					
				// 	},
				// 	hide:hideForTransporter,
				// 	suppressColumnsToolPanel:hideForTransporter,
				// },
			);
		}

		columnwithDefs.push(
			{
				headerName:"Batch No",
				field:"consignment_code",
				width:150,
				resizable:true,
				pinned:"left"
			},
			{
				headerName:"Status",
				field:"status",
				width:150,
				resizable:true,
				pinned:"left",
				valueGetter:function(params){
					// console.log()
					try{
						var status = "At Plant"
						if(parseInt(params.data.status) == 1)
						{
							var status = "Inside TPA";
						}
						if(parseInt(params.data.status) == 2)
						{
							var status = "In transit to MY";
						}
						if(parseInt(params.data.status) == 3)
						{
							var status = "Inside MY";
						}
						if(parseInt(params.data.status) == 4)
						{
							var status = "Loading Started";
						}
						if(parseInt(params.data.status) == 5)
						{
							var status = "Loading Completed";
						}
						if(parseInt(params.data.status) == 6)
						{
							var status = "Force closed";
						}
						if(parseInt(params.data.status) == 7)
						{
							var status = "Inside TPA After Loading";
						}
						if(parseInt(params.data.status) == 8)
						{
							var status = "In transit to Loading Yard";
						}
						if(parseInt(params.data.status) == 9)
						{
							var status = "At Loading Yard";
						}
						if(parseInt(params.data.status) == 9 && params.data.unloading_at_loading_yard == 1)
						{
							var status = "Unloading At Loading Yard";
						}
						if(parseInt(params.data.status) == 10)
						{
							var status = "Rake Assigned";
						}
						if(parseInt(params.data.status) == 11)
						{
							var status = "In transit to Destination Yard";
						}
						if(parseInt(params.data.status) == 12)
						{
							var status = "Reached Destination Yard";
						}
						if(parseInt(params.data.status) == 12 && params.data.unloading_at_destination_yard == 1)
						{
							var status = "Unloading At Destination Yard";
						}
						if(parseInt(params.data.status) == 13)
						{
							var status = "Intransit to Customer";
						}						
						if(parseInt(params.data.status) == 14)
						{
							var status = "Near Customer";
						}
						if(parseInt(params.data.status) == 15)
						{
							var status = "Reached Customer";
						}
						if(parseInt(params.data.status) == 16)
						{
							var status = "Confirmed Delivered";
						}
						return status;
					}
					catch(e){

					}
				}
			},
			{
				headerName: "Sim Provider",
				field: "sim_tracking_service_provider",
				width: 100,
				pinned: 'left',
				filter: "agSetColumnFilter",
				hide : isNotSideAdmin,
				cellRenderer: '', resizable: true,
				valueGetter: function(params){
					if(params.data.sim_tracking_service_provider !=undefined && params.data.sim_tracking_service_provider !="")
					{
						if(params.data.sim_tracking_service_provider == 1 ||params.data.sim_tracking_service_provider == "1" ){
							return "Jio"
						}
						else if (params.data.sim_tracking_service_provider == 2 ||params.data.sim_tracking_service_provider == "2"){
							return "Non-Jio"
						}
					}
				},
			},
			{
                headerName:Constant.COL_SHIPMENT_DOC_NO,
                field:"shipment_id",
                width:120,
				hide:hideForTransporter,
				suppressColumnsToolPanel:hideForTransporter,
            },
            {
                headerName:Constant.COL_SHIPMENT_DATE,
                field:"shipment_time",
				hide:hideForTransporter,
				suppressColumnsToolPanel:hideForTransporter,
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                },
				comparator: dateComparator,
            },
            {
                headerName:Constant.COL_PARKING_ENTRY_NO,
                field:"parking_id",
				hide:hideForTransporter,
				suppressColumnsToolPanel:hideForTransporter,
                width:120
            },
            
            {
                headerName:Constant.COL_PARKING_DATE,
                field:"parking_in_time",
				hide:hideForTransporter,
				suppressColumnsToolPanel:hideForTransporter,
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                },
				comparator: dateComparator,
            },
            
            {
                headerName:Constant.COL_TRUCK_NO,
                field:"truck_no",
                width:120
            },
            // {
            //     headerName:Constant.COL_TRUCK_TYPE,
            //     field:"vehicle_mode",
            //     width:80,
			// 	hide:hideForTransporter,
			// 	suppressColumnsToolPanel:hideForTransporter,
            // },                   
			{
				headerName: "Consigner Code",
				field: "consigner_code",
				width: 100,
			},
            // {
            //     headerName:Constant.COL_SHIPMENT_MODE,
            //     field:"shipment_mode",
            //     width:80
            // },

			{
				headerName:"First Mile Tracking",
				field:"type_of_tracking_cons",
				width:200,
				hide:hideForTransporter,
				suppressColumnsToolPanel:hideForTransporter, 
				valueGetter: function(params){
					if(params.data.type_of_tracking_cons !=undefined && params.data.type_of_tracking_cons !="")
					{
						if(params.data.type_of_tracking_cons == 0)
						{
							return "Waiting For GPS";
						}
						else if(params.data.type_of_tracking_cons == 1)
						{
							return "GPS Tracking";
						}
						else if(params.data.type_of_tracking_cons == 2)
						{
							return "SIM Tracking";
						}
						else if(params.data.type_of_tracking_cons == 3)
						{
							return "GPS & SIM Tracking";
						}
						else if(params.data.type_of_tracking_cons == 4)
						{
							return "Sim Tracking Consent Pending";
						}
					}
					else
					{
						return "Waiting For GPS";
					}
				}
			},
			{
				headerName:"First Mile Active On",
				field:"first_mile_last_active_on",
				width:200,
				hide:hideForTransporter,
				suppressColumnsToolPanel:hideForTransporter,
				valueGetter:function(params){
                    try{
                        if(params.data.first_mile_last_active_on != "" && params.data.first_mile_last_active_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_mile_last_active_on);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                },
				comparator: dateComparator,
			},
			{
				headerName:Constant.COL_TPT_NAME,
				field:"transporter_name",
				width:200,
			},
			{
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                width:120,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                width:120,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                width:120,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                width:200,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                width:120,
            },
            {
                headerName:Constant.MATERIAL_INFO,
                field:"material_desc",
                resizable : true,
                filter : true,
                width:120,
            },
            {
                headerName:Constant.SR_GRADE,
                field:"sr_grade",
                resizable : true,
                filter : true,
                width:120,
            },
			{
                headerName:"mm coordinates available",
                field:"is_mm_coords_available",
                resizable : true,
                filter : true,
                width:120,
				hide:true
            },

            {
                headerName:Constant.COL_CUSTOMER_NAME,
                field:"consignee_name",
                width:200,
            },
            {
                headerName:Constant.COL_CUSTOMER_CODE,
                field:"consignee_code",
                width:120,
            },
            {
                headerName:Constant.COL_CUSTOMER_CITY,
                field:"transporter_zone",
                width:140,
            },
            {
                headerName:Constant.COL_CONSIGNEE_ADDRESS,
                field:"consignee_address",
                resizable : true,
                filter : true,
                width:140,
            },
			// {
			// 	headerName:"ETA Dealer",
			// 	field:"destination_dealer_expected_eta",
			// 	width:160,
			// 	resizable:true,
			// 	pinned:"left",
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.destination_dealer_expected_eta != "" && params.data.destination_dealer_expected_eta != undefined)
			// 			{
			// 				return getDDMMMYYYYHHMMDefault(params.data.destination_dealer_expected_eta);
			// 			}
			// 			else{
			// 				return "";
			// 			}
			// 		}
			// 		catch(e){
			// 			return "";
			// 		}
			// 	}
			// },
			// {
			// 	headerName:"Revised ETA Dealer",
			// 	field:"destination_dealer_revised_eta",
			// 	width:160,
			// 	resizable:true,
			// 	pinned:"left",
			// 	// cellClass:["cellstylegrid"],
			// 	cellClass:function(params){
			// 		let hourDifference = 0;
			// 		if (params.data.destination_dealer_revised_eta && params.data.destination_dealer_expected_eta) {
			// 			let revisedDateOnlyMoment  = moment(params.data.destination_dealer_revised_eta);
			// 			let expectedDateOnlyMoment  = moment(params.data.destination_dealer_expected_eta);
			// 			hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
			// 			//console.log("diff= ", params.data.consignment_code, hourDifference);
			// 		} else {
			// 			//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
			// 		}

			// 		if(hourDifference >= 1)
			// 		{
			// 			return 'bgColorDangerMedium'
			// 		}

			// 		if(hourDifference <= -1)
			// 		{
			// 			return 'bgColorSuccessMedium'
			// 		}
			// 		else{
			// 			return ''
			// 		}
			// 	},
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.destination_dealer_revised_eta != "" && params.data.destination_dealer_revised_eta != undefined)
			// 			{
			// 				return getDDMMMYYYYHHMMDefault(params.data.destination_dealer_revised_eta);
			// 			}
			// 			else{
			// 				return "";
			// 			}
			// 		}
			// 		catch(e){
			// 			return "";
			// 		}
			// 	}
			// },



			
			{
				headerName: "<i class='icofont icofont-train-line'></i> Plant to Loading Yard",
				headerClass:["cellstylegridG","cellTopHeader"],	
				children:[
					
					
					{
						headerName:"Loading Yard",
						headerClass:["cellstylegridG"],
						field:"rail_siding",
						width:140,
						resizable:true,
						cellClass:["cellstylegridG"]
					},
					// {
					// 	headerName:"Mode",
					// 	headerClass:["cellstylegridG"],
					// 	field:"vehicle_mode",
					// 	width:140,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"]
					// },
					// {
					// 	headerName:"GPS Device ID",
					// 	headerClass:["cellstylegridG"],
					// 	field:"gpsdeviceid",
					// 	width:140,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"]
					// },
					// {
					// 	headerName:"TRC No",
					// 	headerClass:["cellstylegridG"],
					// 	field:"first_mile_trc_no",
					// 	width:140,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"]
					// },
					// {
					// 	headerName:"Eway Bill No",
					// 	headerClass:["cellstylegridG"],
					// 	field:"ewaybill_no",
					// 	width:140,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"]
					// },
					// {
					// 	headerName:"E-Way Bill Date",
					// 	headerClass:["cellstylegridG"],
					// 	field:"ewaybilldate",
					// 	width:160,
					// 	resizable:true,
					// 	valueGetter:function(params){
					// 		try{
					// 			if(params.data.ewaybilldate != undefined && params.data.ewaybilldate != "")
					// 			{
					// 				return getDDMMMYYYYHHMMDefault(params.data.ewaybilldate);
					// 			}
					// 			else
					// 			{
					// 				return "";
					// 			}
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
					// 	},
					// 	cellClass:["cellstylegridG"]
					// },
					// {
					// 	headerName:"E-Way Bill Expiry Date",
					// 	headerClass:["cellstylegridG"],
					// 	field:"ewaybill_expiry_date",
					// 	width:160,
					// 	resizable:true,
					// 	valueGetter:function(params){
					// 		try{
					// 			if(params.data.ewaybill_expiry_date != undefined && params.data.ewaybill_expiry_date != "")
					// 			{
					// 				return getDDMMMYYYYHHMMDefault(params.data.ewaybill_expiry_date);
					// 			}
					// 			else
					// 			{
					// 				return "";
					// 			}
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
					// 	},
					// 	cellClass:["cellstylegridG"]
					// },
					
					// {
					// 	headerName:"Driver Name",
					// 	headerClass:["cellstylegridG"],
					// 	field:"driver_name",
					// 	width:140,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"]
					// },
					
					// {
					// 	headerName:"Driver Mobile",
					// 	headerClass:["cellstylegridG"],
					// 	field:"driver_mobile",
					// 	width:140,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"]
					// },
					// {
					// 	headerName:"Batch No",
					// 	headerClass:["cellstylegridG"],
					// 	field:"batchno",
					// 	width:150,
					// 	resizable:true,
					// 	cellClass:["cellstylegridG"]
					// },
					
					{
						headerName:"Dispatch from Plant",
						headerClass:["cellstylegridG"],
						field:"loading_yard_exit_time",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.loading_yard_exit_time != undefined && params.data.loading_yard_exit_time != "")
								{
									return getDDMMMYYYYHHMMDefault(params.data.loading_yard_exit_time);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"],
						comparator: dateComparator,
					},
					{
						headerName:"Reached To Loading Yard",
						headerClass:["cellstylegridG"],
						field:"loading_yard_reached_on",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.loading_yard_reached_on != undefined && params.data.loading_yard_reached_on != "")
								{
									return getDDMMMYYYYHHMMDefault(params.data.loading_yard_reached_on);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"],
						comparator: dateComparator,
					},
					{
						headerName:"Duration since available at loading stockyard",
						headerClass:["cellstylegridG"],
						field:"loading_yard_reached_on",
						width:200,
						valueGetter:function(params){
							try{
								if(params.data.loading_yard_reached_on != undefined && params.data.loading_yard_reached_on != "")
								{
									var ste = params.data.loading_yard_reached_on;
                                    var stsec = moment.parseZone(ste).format("x");
                                    var etse
                                    if(!['',undefined,'undefined',null].includes(params.data.unloading_at_loading_yard_on)){
                                        var etse = params.data.unloading_at_loading_yard_on
                                    }else{
                                        var etse = moment.parseZone().utcOffset("+00:00")._d;
                                        etse = moment.parseZone(etse).format("YYYY-MM-DD HH:mm:ss");
                                    }
                                    var etsec = moment.parseZone(etse).format("x");
									var diff = (parseInt(etsec) - parseInt(stsec)) / 1000;
                                    var timespent = secondsToDhms(diff);
									return timespent;
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"]
					},
					{
						headerName:"Unloading At Loading Yard",
						headerClass:["cellstylegridG"],
						field:"unloading_at_loading_yard_on",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.unloading_at_loading_yard_on != undefined && params.data.unloading_at_loading_yard_on != "")
								{
									return getDDMMMYYYYHHMMDefault(params.data.unloading_at_loading_yard_on);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"],
						comparator: dateComparator,
					},
					{
						headerName:"Unloading At Loading Yard Update On",
						headerClass:["cellstylegridG"],
						field:"loading_yard_update_on",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.loading_yard_update_on != undefined && params.data.loading_yard_update_on != "")
								{
									return getDDMMMYYYYHHMMDefault(params.data.loading_yard_update_on);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridG"],
						comparator: dateComparator,
					},
					{
						headerName:"Truck No",
						headerClass:["cellstylegridG"],
						field:"truck_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.truck_no != '' && typeof params.data.truck_no != 'undefined')
							{
								return params.data.truck_no;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridG"]
					
					},
				
					// {
					// 	headerName:"Old Trailer No ",
					// 	headerClass:["cellstylegridG"],
					// 	field:"old_truck_no",
					// 	width:140,
					// 	filter:"agSetColumnFilter",
					// 	resizable: true, 
					// 	valueGetter:function(params){
					// 		if(params.data.old_truck_no != '' && typeof params.data.old_truck_no != 'undefined')
					// 		{
					// 			return params.data.old_truck_no;
					// 		} 
					// 		else{
					// 			return '';
					// 		}
					// 	},
					// 	cellClass:["cellstylegridG"]
					
					// },
					// {
					// 	headerName:"Is Transshipment ",
					// 	headerClass:["cellstylegridG"],
					// 	field:"is_transshipment",
					// 	width:140,
					// 	filter:"agSetColumnFilter",
					// 	resizable: true, 
					// 	valueGetter:function(params){
					// 		if(params.data.is_transshipment != '' && typeof params.data.is_transshipment != 'undefined')
					// 		{
					// 			if(params.data.is_transshipment == 1){
					// 				return "Yes"
					// 			}
					// 			else{
					// 				return "No";
					// 			}
					// 		} 
					// 		else{
					// 			return '';
					// 		}
					// 	},
					// 	cellClass:["cellstylegridG"]
					
					// },
				]
			},
			{
				headerName: "<i class='icofont icofont-train-line'></i> Loading Yard to Destination Yard",
				headerClass:["cellstylegridSk","cellTopHeader"],	
				children:[
					// {
					// 	headerName:"UnLoading At Loading Yard",
					// 	headerClass:["cellstylegridSk"],
					// 	field:"unloading_at_loading_yard_on",
					// 	width:160,
					// 	resizable:true,
					// 	cellClass:["cellstylegridSk"],
					// 	valueGetter:function(params){
					// 		try{
					// 			if(params.data.unloading_at_loading_yard_on != "" && params.data.unloading_at_loading_yard_on != undefined)
					// 			{
					// 				return getDDMMMYYYYHHMMDefault(params.data.unloading_at_loading_yard_on);
					// 			}
					// 			else{
					// 				return "";
					// 			}
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
					// 	}
					// },
					// {
					// 	headerName:"Start Rail Loading",
					// 	headerClass:["cellstylegridSk"],
					// 	field:"rail_start_loading_at",
					// 	width:160,
					// 	resizable:true,
					// 	cellClass:["cellstylegridSk"],
					// 	valueGetter:function(params){
					// 		try{
					// 			if(params.data.rail_start_loading_at != "" && params.data.rail_start_loading_at != undefined)
					// 			{
					// 				return getDDMMMYYYYHHMMDefault(params.data.rail_start_loading_at);
					// 			}
					// 			else{
					// 				return "";
					// 			}
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
					// 	}
					// },
					{
						headerName:"Loading Yard",
						headerClass:["cellstylegridSk"],
						field:"rail_siding",
						width:160,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					{
						headerName:"Rake No",
						headerClass:["cellstylegridSk"],
						field:"rake_no",
						width:120,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					{
						headerName:"FNR No",
						headerClass:["cellstylegridSk"],
						field:"fnr_no",
						width:120,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					{
						headerName:"Rake Updated On",
						headerClass:["cellstylegridSk"],
						field:"rake_updated_on",
						width:120,
						resizable:true,
						cellClass:["cellstylegridSk"],
						valueGetter:function(params){
							try{
								if(params.data.rake_updated_on != "" && params.data.rake_updated_on != undefined)
								{
									return getHyphenDDMMMYYYYHHMM(params.data.rake_updated_on);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridSk"],
						comparator: dateComparator,
					},
					{
						headerName:"Last FNR Status",
						headerClass:["cellstylegridSk"],
						field:"last_fnr_status",
						width:300,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					// {
					// 	headerName:"GPS Device Id",
					// 	headerClass:["cellstylegridSk"],
					// 	field:"rake_gps_device_id",
					// 	width:120,
					// 	resizable:true,
					// 	cellClass:["cellstylegridSk"],
					// 	valueGetter:function(params)
					// 	{
					// 		try{
					// 			var devc = params.data.rake_gps_device_id;
					// 			if(params.data.rake_gps_device_id_2 != "" && params.data.rake_gps_device_id_2 != undefined)
					// 			{
					// 				devc = devc+","+params.data.rake_gps_device_id_2;
					// 			}
					// 			else{
					// 				devc = devc;
					// 			}
					// 			return devc;
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
					// 	}
					// },
					{
						headerName:"Destination Yard",
						headerClass:["cellstylegridSk"],
						field:"destination_rail_siding",
						width:120,
						resizable:true,
						cellClass:["cellstylegridSk"]
					},
					
					
					{
						headerName:"Rail Dispatch Time",
						headerClass:["cellstylegridSk"],
						field:"expected_rake_dispatch_date",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.expected_rake_dispatch_date != "" && params.data.expected_rake_dispatch_date != undefined)
								{
									return getHyphenDDMMMYYYYHHMM(params.data.expected_rake_dispatch_date);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridSk"],
						comparator: dateComparator,
					},
				
					{
						headerName:"Reached To Destination Yard",
						headerClass:["cellstylegridSk"],
						field:"rake_delivery_point_reached_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.rake_delivery_point_reached_on != '' && typeof params.data.rake_delivery_point_reached_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.rake_delivery_point_reached_on);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridSk"],
						comparator: dateComparator,						
					},
					{
						headerName:"Duration since available at unloading stockyard",
						headerClass:["cellstylegridSk"],
						field:"rake_delivery_point_reached_on",
						width:200,
						valueGetter:function(params){
							try{
								if(params.data.rake_delivery_point_reached_on != undefined && params.data.rake_delivery_point_reached_on != "")
								{
									var ste = params.data.rake_delivery_point_reached_on;
									var stsec = moment.parseZone(ste).format("x");
									var etse
									if(!['',undefined,'undefined',null].includes(params.data.unloading_at_destination_yard_on)){
                                        var etse = params.data.unloading_at_destination_yard_on
                                    }else{
                                        var etse = moment.parseZone().utcOffset("+00:00")._d;
                                        etse = moment.parseZone(etse).format("YYYY-MM-DD HH:mm:ss");
                                    }
									var etsec = moment.parseZone(etse).format("x");
									var diff = (parseInt(etsec)-parseInt(stsec))/1000;
									var timespent = secondsToDhms(diff);
									return timespent;
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						},
						cellClass:["cellstylegridSk"]
					},
					{
						headerName:"Unloading At Destination Yard",
						headerClass:["cellstylegridSk"],
						field:"unloading_at_destination_yard_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.unloading_at_destination_yard_on != '' && typeof params.data.unloading_at_destination_yard_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.unloading_at_destination_yard_on);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridSk"],
						comparator: dateComparator,						
					},				
					{
						headerName:"Unloading At Destination Yard Updated On",
						headerClass:["cellstylegridSk"],
						field:"dest_arrival_updated_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.dest_arrival_updated_on != '' && typeof params.data.dest_arrival_updated_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.dest_arrival_updated_on);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegridSk"],
						comparator: dateComparator,						
					},		
					

				]
			},

			
			{
				headerName: "<i class='icofont icofont-train-line'></i> Destination Yard to Customer",
				headerClass:["cellstylegrid","cellTopHeader"],	
				children:[	
					// {
					// 	headerName:"Vehicle Unloading Time ",
					// 	headerClass:["cellstylegrid"],
					// 	field:"vehicle_unloading_time",
					// 	width:140,
					// 	filter:"agSetColumnFilter",
					// 	resizable: true, 
					// 	valueGetter:function(params){
					// 		if(params.data.vehicle_unloading_time != '' && typeof params.data.vehicle_unloading_time != 'undefined')
					// 		{
					// 			return getDDMMMYYYYHHMMDefault(params.data.vehicle_unloading_time);
					// 		} 
					// 		else{
					// 			return '';
					// 		}
					// 	},
					// 	cellClass:["cellstylegrid"]
						
					// },		
					// {
					// 	headerName:"Mode ",
					// 	headerClass:["cellstylegrid"],
					// 	field:"last_mile_dealer_mode_type",
					// 	width:140,
					// 	filter:"agSetColumnFilter",
					// 	resizable: true, 
					// 	valueGetter:function(params){
					// 		if(params.data.last_mile_dealer_mode_type != '' && typeof params.data.last_mile_dealer_mode_type != 'undefined')
					// 		{
					// 			return params.data.last_mile_dealer_mode_type;
					// 		} 
					// 		else{
					// 			return '';
					// 		}
					// 	},
					// 	cellClass:["cellstylegrid"]
						
					// },
					
					
					{
						headerName:"Truck No",
						headerClass:["cellstylegrid"],
						field:"last_mile_truck_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_truck_no != '' && typeof params.data.last_mile_truck_no != 'undefined')
							{
								return params.data.last_mile_truck_no;
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegrid"]
						
					},
					// {
					// 	headerName:"Customer Code",
					// 	headerClass:["cellstylegrid"],
					// 	field:"last_mile_destination_dealer_code",
					// 	width:120,
					// 	resizable:true,
					// 	cellClass:["cellstylegrid"]
					// },
					// {
					// 	headerName:"Customer Name",
					// 	headerClass:["cellstylegrid"],
					// 	field:"last_mile_destination_dealer_name",
					// 	width:160,
					// 	resizable:true,
					// 	cellClass:["cellstylegrid"]
					// },
										
					{
						headerName:"Last Mile Dispatch Date ",
						headerClass:["cellstylegrid"],
						field:"last_mile_dispatch_date",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_dispatch_date != '' && typeof params.data.last_mile_dispatch_date != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.last_mile_dispatch_date);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegrid"],
						comparator: dateComparator,						
					},
										
					{
						headerName:"Driver Name",
						headerClass:["cellstylegrid"],
						field:"last_mile_driver_name",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true,
						cellClass:["cellstylegrid"]
						
					},
										
					{
						headerName:"Driver Mobile",
						headerClass:["cellstylegrid"],
						field:"last_mile_driver_mobile_no",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true,
						cellClass:["cellstylegrid"]
						
					},
										
					{
						headerName:"Last Mile Updated On",
						headerClass:["cellstylegrid"],
						field:"last_mile_updated_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_updated_on != '' && typeof params.data.last_mile_updated_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.last_mile_updated_on);
							} 
							else{
								return '';
							}
						},
						cellClass:["cellstylegrid"],
						comparator: dateComparator,						
					},
					
					// {
					// 	headerName:"Delivery Confirm ",
					// 	headerClass:["cellstylegrid"],
					// 	field:"confirm_delivery_date",
					// 	width:140,
					// 	filter:"agSetColumnFilter",
					// 	hide:hideForTransporter,
					// 	suppressColumnsToolPanel:hideForTransporter,
					// 	resizable: true, 
					// 	valueGetter:function(params){
					// 		try{
					// 			if(params.data.confirm_delivery_date != '' && typeof params.data.confirm_delivery_date != 'undefined')
					// 			{
					// 				return getDDMMMYYYYHHMMDefault(params.data.confirm_delivery_date)
								
					// 			} 
					// 			else{
					// 				return '';
					// 			}
					// 		}
					// 		catch(e){
					// 			return "";
					// 		}
							
					// 	},
					// 	cellClass:["cellstylegrid"]
						
					// },

				]
			},

			// {
			// 	headerName: "<i class='icofont icofont-train-line'></i> Invoice Data",
			// 	headerClass:["cellstylegridB","cellTopHeader"],	
			// 	children:[
			
					
			// 		{
			// 			headerName:"Bill To Party",
			// 			headerClass:["cellstylegridB"],
			// 			field:"bill_to_party",
			// 			width:150,
			// 			resizable:true,
			// 			cellClass:["cellstylegridB"]
			// 		},
					
			// 		{
			// 			headerName:"Bill To Party Name",
			// 			headerClass:["cellstylegridB"],
			// 			field:"bill_to_party_name",
			// 			width:150,
			// 			resizable:true,
			// 			cellClass:["cellstylegridB"]
			// 		},
					
			// 		{
			// 			headerName:"Address",
			// 			headerClass:["cellstylegridB"],
			// 			field:"bill_to_party_address",
			// 			width:150,
			// 			resizable:true,
			// 			cellClass:["cellstylegridB"]
			// 		},
			
					
			// 		{
			// 			headerName:"City",
			// 			headerClass:["cellstylegridB"],
			// 			field:"bill_to_party_city",
			// 			width:150,
			// 			resizable:true,
			// 			cellClass:["cellstylegridB"]
			// 		},
			// 		{
			// 			headerName:"State",
			// 			headerClass:["cellstylegridB"],
			// 			field:"bill_to_party_state",
			// 			width:150,
			// 			resizable:true,
			// 			cellClass:["cellstylegridB"]
			// 		},
					
			
			// 	]
			// }
			{
				headerName:"Last Mile Tracking",
				field:"type_of_tracking_last_mile_cons",
				width:200,
				// hide:hideForTransporter,
				// suppressColumnsToolPanel:hideForTransporter, 
				valueGetter: function(params){
					if(params.data.type_of_tracking_last_mile_cons !=undefined && params.data.type_of_tracking_last_mile_cons !="")
					{
						if(params.data.type_of_tracking_last_mile_cons == 0)
						{
							return "Waiting For GPS";
						}
						else if(params.data.type_of_tracking_last_mile_cons == 1)
						{
							return "GPS Tracking";
						}
						else if(params.data.type_of_tracking_last_mile_cons == 2)
						{
							return "SIM Tracking";
						}
						else if(params.data.type_of_tracking_last_mile_cons == 3)
						{
							return "GPS & SIM Tracking";
						}
						else if(params.data.type_of_tracking_last_mile_cons == 4)
						{
							return "Sim Tracking Consent Pending";
						}
					}
					else
					{
						return "Waiting For GPS";
					}
				}
			},
			{
				headerName:"Last Mile Active On",
				field:"last_mile_last_active_on",
				width:200,
				// hide:hideForTransporter,
				// suppressColumnsToolPanel:hideForTransporter,
                valueGetter:function(params){
                    try{
                        if(params.data.last_mile_last_active_on != "" && params.data.last_mile_last_active_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.last_mile_last_active_on);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                },
				comparator: dateComparator,
			},
			{
                headerName:"Last Mile LR No",
                field:"last_mile_lr_no",
                width:140,
            },
			
		);
        const { calview } = this.state;
        const events = [
            {
                start: '2015-07-20',
                end: '2015-07-02',
                eventClasses: 'optionalEvent',
                title: 'test event',
                description: 'This is a test description of an event',
            },
            {
                start: '2015-07-19',
                end: '2015-07-25',
                title: 'test event',
                description: 'This is a test description of an event',
                data: 'you can add what ever random data you may want to use later',
            },
        ];
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
				{/* {(this.state.rake_no == "default")? */}
				{(localStorage.getItem("roles").indexOf("RAIL") >= 0)? "":
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
                        <ul className="ul-tabs">
							{(localStorage.getItem("user_type") !="CUSTOMER")?
                            <li className="">
                                <a href={"/shipmenttrucks"}>Inter Plant Movement</a>
                            </li>
							:""}
                            <li>
                            {(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0 )?
                                <a href={"/customertrucks"}>Active Trucks</a>
                            :""}
                            {(localStorage.getItem('roles').indexOf("MARKETING") >= 0 )?
                                <a href={"/customertrucks"}>Active Trucks</a>
                            :""}
                            {(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0 || localStorage.getItem('roles').indexOf("MARKETING") >= 0 )? "":
                                <a href={"/sndtrucks"}>Intransit-Road</a>
                            }
                            </li>
                            {(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0 || localStorage.getItem('roles').indexOf("MARKETING") >= 0  )?"":
                            <li>
                                <a className="active" href={"/rail-consignments/default"}>Intransit-Rail</a>
                            </li>
                            }
                        </ul>
                    </div>
				</div>
				}
				<div className="row">
					{(localStorage.getItem('roles').indexOf("AMNS_OPS") >= 0 || localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0 && localStorage.getItem("pc")=="HZ") ? 
					<>
					<div className="col-xl-4 col-lg-4">
					<div className="card">
						<div className="card-body" style={{padding:"21px"}}>
							<form className="row" onSubmit={this.onSubmitTransporter.bind(this)}>
								<div className="form-group col-xl-8">
									<label>Select Transporter</label>
									<Select
										className="border-radius-0"
										value={this.state.selectedTransporter}
										isMulti={false}
										id="selectedFilter"
										style={{ borderRadius: "0px" }}
										options={this.state.transporterOptions}
										onChange={this.onchangeTransporterType.bind(this)}
										required />
								</div>
								<div className="form-group col-xl-4" style={{ float:'right',marginTop:"30px"}}>
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
					</div>
					<div className="col-xl-8 col-lg-8 col-sm-8 beffect pl-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"firstMile")}>
											<span className="f13"><i className="icofont icofont-truck f22 txt-info"></i><br />First Mile (Truck)</span>
											<h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.firstMileData}/></span></h4>
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"middleMile")}>
											<span className="f13"><i className="icofont icofont-train-line f22 txt-warning"></i><br />Middle Mile (Rail)</span>
											<h4 className="txt-warning f35"><span className="counter"><CountUp end={this.state.middleMile}/></span></h4>
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"lastMile")}>
											<span className="f13"><i className="icofont icofont-truck f22 txt-success"></i><br />Last Mile (Truck)</span>
											<h4 className="txt-success f35"><span className="counter"><CountUp end={this.state.lastMile}/></span></h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> 
					</>
					:""}
					<div className="col-xl-12 col-lg-12 col-sm-12 beffect pl-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
										{(localStorage.getItem('roles').indexOf("AMNS_OPS") >= 0 || localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0) ?
										<div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
											<span className="f13"><i className="icofont icofont-ui-browser f24 txt-info"></i><br /> Total<br /><br /><br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalvins != '')?this.state.totalvins:0}/></span></h4>
											<p>Total Quantity: {this.state.totalvins_quantity} (Tons) </p>
										</div>
										:""}
										{/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atplant")}>
											<span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br />At Plant<br /><br /><br /><br /></span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.atplant != '')?this.state.atplant:0}/></span></h4>
											
										</div> */}
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitload")}>
											<span className="f13"><i className="icofont icofont-clock-time f22 txt-info"></i><br /> In Transit To Rail Loading Yard<br /><br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.intransitload != '')?this.state.intransitload:0}/></span></h4>
											<p>Total Quantity: {this.state.intransitload_quantity} (Tons)</p>

											
										</div>
										{/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atload")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-warning"></i><br /> At Rail Loading Yard<br /><br /></span>
											<h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.atload != '')?this.state.atload:0}/></span></h4>
											
										</div> */}
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloadingatloadingyard")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-info"></i><br /> Unloading At Loading Yard<br /><br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.unloadingatloadingyard != '')?this.state.unloadingatloadingyard:0}/></span></h4>
											<p>Total Quantity  {this.state.unloadingatloadingyard_quantity} (Tons)</p>

										</div>
										
										{/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"startrail")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-danger"></i><br /> Start Rail Loading<br /><br /><br /></span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.startrailloading != '')?this.state.startrailloading:0}/></span></h4>
											
										</div> */}
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"rakeassign")}>
											<span className="f13"><i className="icofont icofont-toy-train f22 txt-warning"></i><br /> Rake Assigned &amp; In-Transit<br /><br /> </span>
											<h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.rakeassign != '')?this.state.rakeassign:0}/></span></h4>
											<h6 className="txt-warning">Total Rakes - {this.state.uniqueRakes}</h6>
											<p>Total Quantity  {this.state.rakeassign_quantity} (Tons)</p>

										
										</div>
										{/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitdest")}>
											<span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> In Transit To Rail Destination Yard</span>
											<h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.intransitdest != '')?this.state.intransitdest:0}/></span></h4>
											
										</div> */}
									
										
									{/* </div>
								</div>
							
							</div>
						</div>
					</div>

                    <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row"> */}

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atdestination")} >
                                            <span className="f13"><i className="icofont icofont-truck-alt f24 txt-warning"></i><br /> Reached Rail Destination Yard<br /><br /></span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.atdestination != '')?this.state.atdestination:0}/></span></h4>
											<p>Total Quantity  {this.state.atdestination_quantity} (Tons)</p>

                                        </div>

										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloadingatdestinationyard")} >
											<span className="f13"><i className="icofont icofont-truck-alt f24 txt-warning"></i><br /> Unloading At Rail Destination Yard<br /><br /></span>
											<h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.unloadingatdestinationyard != '')?this.state.unloadingatdestinationyard:0}/></span></h4>
											<p>Total Quantity  {this.state.unloadingatdestinationyard_quantity} (Tons)</p>

										</div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitdealer")}>
                                            <span className="f13"><i className="icofont icofont-ui-home txt-success f24"></i><br /> In Transit To Customer<br /><br /></span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.intransitdealer != '')?this.state.intransitdealer:0}/></span></h4>
											<p>Total Quantity  {this.state.intransitdealer_quantity} (Tons)</p>

                                            
                                        </div>
										{(localStorage.getItem('roles').indexOf("AMNS_OPS") >= 0 || localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0) ?
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nearcutomer")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-success"></i><br /> Near Customer<br /><br /><br /></span>
											<h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.nearcutomer != '')?this.state.nearcutomer:0}/></span></h4>
											<p>Total Quantity  {this.state.nearcutomer_quantity} (Tons)</p>

											
										</div>
										:""}
										{(localStorage.getItem('roles').indexOf("AMNS_OPS") >= 0 || localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0) ?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"neardealer")} >
                                            <span className="f13"><i className="icofont icofont-truck-alt f24 txt-success"></i><br /> Reached Customer <br /><br /><br /></span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.neardealer != '')?this.state.neardealer:0}/></span></h4>
											<p>Total Quantity  {this.state.neardealer_quantity} (Tons)</p>

                                        </div>
										:""}

                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"delivered")} >
                                            <span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> Delivered<br /><br /></span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.confirmdelivers != '')?this.state.confirmdelivers:0}/></span></h4>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>

				<div className="row">
				{/* {(localStorage.getItem("roles").indexOf("Loading Officer")  >= 0
				 || localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0
				 || localStorage.getItem("roles").indexOf("SITE_ADMIN")  >= 0
				 || localStorage.getItem("roles").indexOf("admin")  >= 0
				 || localStorage.getItem("roles").indexOf("prod")  >= 0)?
					<div className="col beffect n-p-0">
						<div className="card">
							<div className="card-body" style={{padding:"15px"}}>
								<h4 className="f16">First Mile</h4>
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer">
											<span className="f13">Avg. Travel Time (Hrs)</span>
											<h4 className="txt-info f25">
												<span className="counter">
													<CountUp end={(this.state.fmile.length != 0)?this.state.fmile.avg_travel_time_hrs:0}/>
												</span>
											</h4>
										</div>
										<div className="col cursorPointer">
											<span className="f13">Avg. Break Time (Hrs)</span>
											<h4 className="txt-danger f25">
												<span className="counter">
													<CountUp end={(this.state.fmile.length != 0)?this.state.fmile.avg_break_time_hrs:0}/>
												</span>
											</h4>
											
										</div>
										<div className="col cursorPointer">
											<span className="f13"> Avg. Speed (Kmph)</span>
											<h4 className="txt-secondary f25">
												<span className="counter">
													<CountUp end={(this.state.fmile.length != 0)?this.state.fmile.avg_speed_kmph:0}/>
												</span>
											</h4>
											
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					:""} */}
					{/* {(localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0
				 || localStorage.getItem("roles").indexOf("SITE_ADMIN")  >= 0
				 || localStorage.getItem("roles").indexOf("admin")  >= 0
				 || localStorage.getItem("roles").indexOf("prod")  >= 0)?
					<div className="col beffect pr-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<h4 className="f16">Middle Mile</h4>
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer">
											<span className="f13">Avg. Travel Time (Hrs)</span>
											<h4 className="txt-info f25">
												<span className="counter">
													<CountUp end={(this.state.mmile.length != 0)?this.state.mmile.avg_travel_time_hrs:0}/>
												</span>
											</h4>
										</div>
										<div className="col cursorPointer">
											<span className="f13">Avg. Break Time (Hrs)</span>
											<h4 className="txt-danger f25">
												<span className="counter">
													<CountUp end={(this.state.mmile.length != 0)?this.state.mmile.avg_break_time_hrs:0}/>
												</span>
											</h4>
											
										</div>
										<div className="col cursorPointer">
											<span className="f13"> Avg. Speed (Kmph)</span>
											<h4 className="txt-secondary f25">
												<span className="counter">
													<CountUp end={(this.state.mmile.length != 0)?this.state.mmile.avg_speed_kmph:0}/>
												</span>
											</h4>
											
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					:""} */}
					{/* {(localStorage.getItem("roles").indexOf("Unloading Officer")  >= 0
				 || localStorage.getItem("roles").indexOf("MahindraAdmin")  >= 0
				 || localStorage.getItem("roles").indexOf("SITE_ADMIN")  >= 0
				 || localStorage.getItem("roles").indexOf("admin")  >= 0
				 || localStorage.getItem("roles").indexOf("prod")  >= 0)?
					<div className="col beffect pr-0">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<h4 className="f16">Unloading Yard to RSO</h4>
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer">
											<span className="f13">Avg. Travel Time (Hrs)</span>
											<h4 className="txt-info f25">
												<span className="counter">
													<CountUp end={(this.state.ulmile.length != 0)?this.state.ulmile.avg_travel_time_hrs:0}/>
												</span>
											</h4>
										</div>
										<div className="col cursorPointer">
											<span className="f13">Avg. Break Time (Hrs)</span>
											<h4 className="txt-danger f25">
												<span className="counter">
													<CountUp end={(this.state.ulmile.length != 0)?this.state.ulmile.avg_break_time_hrs:0}/>
												</span>
											</h4>
											
										</div>
										<div className="col cursorPointer">
											<span className="f13"> Avg. Speed (Kmph)</span>
											<h4 className="txt-secondary f25">
												<span className="counter">
													<CountUp end={(this.state.ulmile.length != 0)?this.state.ulmile.avg_speed_kmph:0}/>
												</span>
											</h4>
											
										</div>
                                    </div>
									
                                </div>
                            </div>
                        </div>
                    </div>
					:""} */}
				
				</div>

            	<div className="row">
                  
                    <div className={"col-xl-12 col-lg-12 "}>
                       
                    </div>
            		<div className="col-xl-12 col-lg-12 pl-0">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Rail Consignments</span>
									   <button className={"float-right btn btn-danger f14 "} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        	<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className={"float-right btn btn-info f14 "} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
										
										{(this.state.railStatus == 8 && localStorage.getItem('user_type')=="TRANSPORTER")?
											<button className={"float-right btn btn-warning f14 "} style={{marginRight:"1px",paddingBottom:"5px",borderBottom:"0px"}} onClick={this.onClickShowatLoadingyard.bind(this)} >
												<a className="btn" style={{color:"#333", padding:"0px"}}>
												Unloading at Loading Yard 
												</a>
											</button>
											:""}
										
										{(this.state.railStatus == 9 && localStorage.getItem('user_type')=="TRANSPORTER")?
										<button className={"float-right btn btn-warning f14 "} style={{marginRight:"1px",paddingBottom:"5px",borderBottom:"0px"}} onClick={this.onClickShowRakeSidebar.bind(this)}>
											<a className="btn" style={{color:"#333", padding:"0px"}}>
											Add Rake Schedule
											</a>
										</button>
										:""}
										{(this.state.railStatus == 12 && this.state.unloading_at_destination == 0 && localStorage.getItem('user_type')=="TRANSPORTER")?
										<button className={"float-right btn btn-warning f14 "} style={{marginRight:"1px",paddingBottom:"5px",borderBottom:"0px"}}
										onClick={this.onClickForceMoveDestinationYard.bind(this)}>
											<a className="btn" style={{color:"#333", padding:"0px"}}>
											Unloading At Destination Yard
											</a>
										</button>
										:""}
										{(this.state.railStatus == 12 && this.state.unloading_at_destination == 1 && localStorage.getItem('user_type')=="TRANSPORTER")?
										<button className={"float-right btn btn-warning f14 "} style={{marginRight:"1px",paddingBottom:"5px",borderBottom:"0px"}} onClick={this.onClickLastMileTruck.bind(this)}>
											<a className="btn" style={{color:"#333", padding:"0px"}}>
											Last Mile Transport
											</a>
										</button>
										:""}
										{/* {(this.state.railStatus == 13)?
										<button className={"float-right btn btn-warning f14 "} style={{marginRight:"1px",paddingBottom:"5px",borderBottom:"0px"}}>
											<a href={"/raildeliveredconfirm/"+this.state.rake_no} className="btn" style={{color:"#333", padding:"0px"}}>
											Confirm Delivered
											</a>
										</button>
										:""} */}
									   {/* <Dropdown className={"float-right col-xl-1 col-lg-1 col-md-1 btn-primary"} style={{marginRight:"20px", padding:"0px"}} isOpen={this.state.dropdownOpen} toggle={this.toggle}>

										<DropdownToggle className="dropbtn" color="warning" caret>
											Actions
										</DropdownToggle>
										<DropdownMenu>
										<span>											
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href={"/railloadingyardarrival/"+this.state.rake_no} className="btn" style={{color:"#333", padding:"0px"}}>
												Loading Yard Arrival
												</a>
											</DropdownItem>
											
											<DropdownItem onClick={this.onClickShowRakeSidebar.bind(this)} style={{color:"#333", padding:"10px"}}>
												Add Rake Schedule
											</DropdownItem>
										</span>
										<span>
											<DropdownItem onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{color:"#333", padding:"10px"}}>
												Destination Yard Arrival
											</DropdownItem>										
											<DropdownItem style={{color:"#333", padding:"10px"}}>
												<a href={"/lastmilebatch/"+this.state.rake_no} style={{color:"#333"}}>
												Last Mile Transport (Customer)
												</a>
											</DropdownItem>
											<DropdownItem onClick={this.onClickDealerArrival.bind(this)} style={{color:"#333", padding:"10px"}}>
												Force Closure
											</DropdownItem>
											<DropdownItem style={{paddingLeft:"10px"}}>
												<a href={"/raildeliveredconfirm/"+this.state.rake_no} className="btn" style={{color:"#333", padding:"0px"}}>
												Confirm Delivered
												</a>
											</DropdownItem>
										</span>
										</DropdownMenu>
										
										</Dropdown> */}
								</h5>
				   			</div>
				   			<div className="card-body row">

                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											masterDetail={true}
											detailCellRendererParams={this.state.detailCellRendererParams}
									
										/>

                                    </div>
                                </div>
                                
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderRakeTranslate1)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                        Unloading At loading yard
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUnloadingatLoadingYard.bind(this)}>
                                
                            {/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Loading Yard *</label>
                                
                                <Select 
                                placeholder={"Select Loading Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.sourceopt}
                                options={this.selectsources()} />  
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="">Rake No *</label>
								<CreatableSelect
									isClearable
									onChange={this.handleChange}
									value={this.state.rake_no}
									style={{padding:"0"}}
									className="col-sm-12" 
									// onInputChange={this.handleInputChange}
									options={this.state.rakeList}
								/> 
								<Select 
                                placeholder={"Select Rake No"}
                                closeMenuOnSelect={true}
                                onChange={this.handleChange} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px",padding:"0px"}}
                                // value={this.state.rake_no}
                                options={this.rakeList()} />
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="">FNR No </label>
								<input 
									type="text" 
									name="fnr_no"
									value={this.state.fnr_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div> */}
							     
                            {/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
								closeMenuOnSelect={true}
								isMulti={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdeviceids}
                                options={this.gpsdevices()} />  
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="">Dispatch Date and Time *</label>
							    <input type="text" placeholder="Dispatch Date and Time" id="dest_yard_reachedon" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination Yard *</label>
                                
                                <Select 
                                placeholder={"Select Destination Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destopt}
                                options={this.selectdestinations()} />  
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Unloading Officer:</label>
								<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
								<Select 
									placeholder={"Select Unloading Officer"}
									closeMenuOnSelect={true}
									onChange={this.onChangeLoadingOfficer.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.loading_officer}
									options={this.loadingofficers()} 
								/> 
								</div>
							</div> */}
						
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler1}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/at_loading_yard_sample_template_1.csv')} target="_blank">Sample Template</a>
							
							</div>
						</form>

                        </div>
					</div>
				</div>
				
				<div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Rake Schedule
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRakeSchedule.bind(this)}>
                                
                            {/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Loading Yard *</label>
                                
                                <Select 
                                placeholder={"Select Loading Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.sourceopt}
                                options={this.selectsources()} />  
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="">Rake No *</label> */}
								{/* <CreatableSelect
									isClearable
									onChange={this.handleChange}
									value={this.state.rake_no}
									style={{padding:"0"}}
									className="col-sm-12" 
									// onInputChange={this.handleInputChange}
									options={this.state.rakeList}
								/> */}
								{/* <Select 
                                placeholder={"Select Rake No"}
                                closeMenuOnSelect={true}
                                onChange={this.handleChange} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px",padding:"0px"}}
                                // value={this.state.rake_no}
                                options={this.rakeList()} />
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="">FNR No </label>
								<input 
									type="text" 
									name="fnr_no"
									value={this.state.fnr_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div> */}
							     
                            {/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
								closeMenuOnSelect={true}
								isMulti={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdeviceids}
                                options={this.gpsdevices()} />  
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="">Dispatch Date and Time *</label>
							    <input type="text" placeholder="Dispatch Date and Time" id="dest_yard_reachedon" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination Yard *</label>
                                
                                <Select 
                                placeholder={"Select Destination Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destopt}
                                options={this.selectdestinations()} />  
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Unloading Officer:</label>
								<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
								<Select 
									placeholder={"Select Unloading Officer"}
									closeMenuOnSelect={true}
									onChange={this.onChangeLoadingOfficer.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.loading_officer}
									options={this.loadingofficers()} 
								/> 
								</div>
							</div> */}
						
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/Add_Rake_Schedule_sample_template_1.csv')} target="_blank">Sample Template</a>
							
							</div>
						</form>

                        </div>
					</div>
				</div>
				
				
				<div className={"slide-r "+(this.state.sliderUploadTOSTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
							Upload Dummy Truck Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUploadTOS.bind(this)}>
                            
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeTOSFileHandler}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_tos.XLSX')} target="_blank">Sample Template</a>
								<p style={{color:"#ff0000",fontSize:"11px"}} className="mt-20p"><b>Note:</b> Following fields should not be empty in uploaded file.</p>
								<p style={{color:"#ff0000",fontSize:"11px"}}>
									Fields: <b>Plat No, TOS NO, Dealer, VIN</b>
								</p>
							</div>
						</form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r "+(this.state.sliderForceDestination1)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Last Mile Transport
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formLastMileTransport.bind(this)}>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination Yard Reached On</label>
							    <input type="text" placeholder="Destination Yard Reached On " id="dest_yard_reached" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div> */}
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeDestFileHandler1}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/last_mile_transport_sample_template_1.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>
            
				<div className={"slide-r "+(this.state.sliderForceDestination)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Unloading At Destination Yard
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceMoveDestinationYardHandler.bind(this)}>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination Yard Reached On</label>
							    <input type="text" placeholder="Destination Yard Reached On " id="dest_yard_reached" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div> */}
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeDestFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/destination_yard_arrival_sample_template_1.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderForceLastMile)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Last Mile Transport Batch
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formLastMileData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination *</label>
                                
                                <Select 
                                placeholder={"Select Destination"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeLastMilDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.lastmiledestination}
                                options={[
									{"value":"RSO", "label":"RSO"},
									{"value":"Dealer", "label":"Dealer"}
								]} />  
                            </div>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">{this.state.desttype} *</label>
                                
                                <Select 
                                placeholder={this.state.desttype}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestType.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destypeopt}
                                options={this.state.destopts} />  
                            </div>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Mode *</label>
                                
                                <Select 
                                placeholder={"Select Mode"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeModeType.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.modetype}
                                options={[
									{"value":"Trailer","label":"Trailer"},
									{"value":"Convoy","label":"Convoy"},
								]} />  
                            </div>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rake_destination_yard_vins_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Update RSO Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRSOData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">RSO Reached On</label>
							    <input type="text" placeholder="RSO Reached On " id="rso_reached" name="rso_reached" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rso_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderLoadingYardTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Consignments
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
								<MLLConsignmentsChildGrid refno={this.state.refno} />
                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderDeviceRetrival)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           GPS Device Retrival
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDeviceRetrival.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdevice}
                                options={this.gpsdevices()} />  
                            </div>
							<div className="form-group mt-20p">
								<label className="">Is Retrived</label> 
								<input 
									type="radio" 
									name="retrived" 
									id="retrivedyes"   
									className="retrivecls"
									value="Yes"
									required  /> Yes

								<input 
									type="radio" 
									name="retrived" 
									id="retrivedno"   
									className="retrivecls"
									value="No"
									required  /> No
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Retrival On</label>
								<input 
									type="text"
									placeholder="Retrival On " 
									id="retrivalon" 
									name="retrivalon" 
									className="datetimepicker_mask form-control" required />
							</div>
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderDealerArrival)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Customer Arrival
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDealerData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer Reached On</label>
							    <input type="text" placeholder="Customer Reached On " id="dealer_reached" name="dealer_reached" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_dealer_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route {this.state.routefor}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								{console.log("this.state.mapinfo ", this.state.mapinfo)}
								<DrawMap 
									context={this}  
									mapFor={"mllrailconsignments"} 
									mapinfo={this.state.mapinfo}
									map_type_of_tracking = {this.state.map_type_of_tracking}
								/>
							
							</div>
							
							{/* <div className="row col-xl-12 col-lg-12 ml-0p n-p-0 mt-20p">
								<div className="col-xl-4 col-lg-4">
									<div className="card-body blc-1">
										<h4 className="map-h pl-10px"><i className="icofont icofont-train-line"></i> First Mile</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
												
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
								<div className="col-xl-4 col-lg-4">
									<div className="card-body blc-2">
										<h4 className="map-h pl-10px"><i className="icofont icofont-train-line"></i> Middle Mile</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
								
								<div className="col-xl-4 col-lg-4">
									<div className="card-body blc-3">
										<h4 className="map-h pl-10px"><i className="icofont icofont-user-alt-2"></i> Last Mile (Customer)</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				:""}


            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$("#retrivalon").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#dealer_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#retrivalon").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	
	$("#dealer_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}      

function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function distinctArrayByWithMultipleKeys(arr,field1,field2) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[field1] == e2[field1] && e1[field2] == e2[field2];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}