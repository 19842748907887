import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { matchesProperty, transform } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');
const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");


var redirectURL = require('../redirectURL');
var moment = require('moment');

class DispatchSummaryDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
			show: false,
			basicType: "",
			basicTitle: "",
			loadshow: "show-n",
			overly: "show-n",
			dayWiseDispatchDays:[],
			dayWiseDispatchDaysCountRoad:[],
			dayWiseDispatchDaysCountRail:[],
			daywiseRoadQty:[],
			daywiseRailQty:[],
			btn_status : props.btn_status,
			url_path : window.location.pathname,
			dispatch_vehicle_data: [],
        };
    }

    componentDidMount() {
		this.fetchDayWiseDispatch();
		this.fetchDispatchVehicleDashboard();
    }
	onClickHideAll = () => {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        })
    }

	
	fetchDayWiseDispatch = async () => {
		try {
			this.setState({ loadshow: "show-m",
            overly: "show-m",})
			const dayWiseDispatchSdate = moment().subtract(1, 'month');
			const startDate = moment(dayWiseDispatchSdate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
			const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
	
			const parameters = {
				start_date: startDate,
				end_date: endDate
			};
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
            {
                parameters.plant_code = encode(localStorage.getItem("pc"));
            }
			const [dispatchResponse, countResponse] = await Promise.all([
				redirectURL.post("/consignments/daywiseDispatchData", parameters),
				redirectURL.post("/consignments/daywiseDispatchCount", parameters)
			]);
	
			const dispatchData = dispatchResponse.data.dispatch_data;
			const countData = countResponse.data.dispatch_data;
	
			const daywiseDispatchDataRoad = dispatchData.filter(e => e.shipment_mode === "ROAD");
			const daywiseDispatchDataRail = dispatchData.filter(e => e.shipment_mode === "RAIL");
	
			const daywiseDispatchDataRoadCount = countData.filter(e => e.shipment_mode === "ROAD");
			const daywiseDispatchDataRailCount = countData.filter(e => e.shipment_mode === "RAIL");
	
			const daywiseRoadQtyArr = daywiseDispatchDataRoad.map(e => e.total_item_quantity);
			const daywiseRailQtyArr = daywiseDispatchDataRail.map(e => e.total_item_quantity);
			const daywiseDispCountRoad = daywiseDispatchDataRoadCount.map(e => e.distinct_trucks_count);
			const daywiseDispCountRail = daywiseDispatchDataRailCount.map(e => e.distinct_trucks_count);
	
			const dayWiseDispatchDays = daywiseDispatchDataRoad.map(e => moment(new Date(e.invoice_time)).format('DD-MMM'));
	
			this.setState({
				dayWiseDispatchDays,
				daywiseRailQty: daywiseRailQtyArr,
				daywiseRoadQty: daywiseRoadQtyArr,
				dayWiseDispatchDaysCountRoad: daywiseDispCountRoad,
				dayWiseDispatchDaysCountRail: daywiseDispCountRail,
				 loadshow: "show-n",
            	overly: "show-n",
			});
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}


    toCamelCase = (str) => {
        return str.replace(/_/g, ' ').replace(/(?:^\w|[A-Z]|\b\w)/g, (letter) => letter);
    };
	fetchDispatchVehicleDashboard = async () => {
		try {
			// this.setState({
			//     loadshow: "show-m",
			//     overly: "show-m",
			// })
			const dayWiseDispatchSdate = moment().subtract(1, 'month');
			const startDate = moment(dayWiseDispatchSdate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
			const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

			const parameters = {
				from_date: startDate,
				to_date: endDate
			};
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
            {
                parameters.plant_code = encode(localStorage.getItem("pc"));
            }
			const [dispatchVehicleCount, countResponse] = await Promise.all([
				redirectURL.post("/consignments/dispatchVehicleCountDashboard", parameters),
				// redirectURL.post("/consignments/daywiseDispatchCount", parameters)
			]);

			const dispatchVehicleCountDashboard = dispatchVehicleCount.data || [];
			console.log(dispatchVehicleCountDashboard, 'dispatchVehicleCountDashboard')

			const format_data_dispatch = {
				date: 'Total',
				day_wise: {
					MY: {
						fence_grop: ''
						, hrs_0_8: 0
						, hrs_8_16: 0,
						hrs_16_24: 0,
						total_hrs: 0
					},
					NMA: {
						fence_grop: ''
						, hrs_0_8: 0,
						hrs_8_16: 0,
						hrs_16_24: 0,
						total_hrs: 0
					},
					DSC: {
						fence_grop: ''
						, hrs_0_8: 0
						, hrs_8_16: 0,
						hrs_16_24: 0
						,
						total_hrs: 0
					},
					CRM: {
						fence_grop: ''
						, hrs_0_8: 0
						, hrs_8_16: 0,
						hrs_16_24: 0,
						total_hrs: 0
					},
					plate_mill: {
						fence_grop: ''
						, hrs_0_8: 0
						, hrs_8_16: 0,
						hrs_16_24: 0,
						total_hrs: 0
					},
					pipe_mill: {
						fence_grop: ''
						, hrs_0_8: 0
						, hrs_8_16: 0,
						hrs_16_24: 0,
						total_hrs: 0
					},
					total: {
						fence_grop: ''
						, hrs_0_8: 0
						, hrs_8_16: 0,
						hrs_16_24: 0,
						total_hrs: 0
					},
				}
			}


			const data_dispatch = dispatchVehicleCountDashboard.map(eachItem => {
				const formattedData = {
					date: eachItem.date,
					day_wise: {

					}
				};

				eachItem.day_wise.forEach(item => {
					const { fence_group, hrs_0_8, hrs_8_16, hrs_16_24 } = item;

					format_data_dispatch.day_wise[fence_group].hrs_0_8 += hrs_0_8

					format_data_dispatch.day_wise[fence_group].hrs_8_16 += hrs_8_16

					format_data_dispatch.day_wise[fence_group].hrs_16_24 += hrs_16_24

					var total_hrs = hrs_0_8 + hrs_8_16 + hrs_16_24

					format_data_dispatch.day_wise[fence_group].total_hrs += total_hrs
					formattedData.day_wise[fence_group] = { ...item, total_hrs };
				});

				return formattedData;
			});


			if (data_dispatch.length > 0) {
				data_dispatch.push(format_data_dispatch)
			}

			console.log(format_data_dispatch, "formattedData");

			this.setState({
				dispatch_vehicle_data: data_dispatch,
				// loadshow: "show-n",
				// overly: "show-n",
			});
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}

    render() {
		const tableHeaderStyle = {
            border: '1px solid #000',
            background: 'rgb(36 120 159)',
            color: 'white',
            textAlign: 'center',
            padding: '8px'
        };

        const tableCellStyle = {
            border: '1px solid #000',
            padding: '6px',
            textAlign: 'center',
            textTransform: 'uppercase',
            width: '120px'
        };
       
		const dispatch_vehicle_data_arr = this.state.dispatch_vehicle_data
		const options = {
			chart: {
				type: 'column'
			},
			title: {
				text: 'Daywise Dispatch Qty (in Tons)' ,
				align: 'left'
			},
			xAxis: {
				categories: this.state.dayWiseDispatchDays,
				crosshair: true,
				accessibility: {
					description: 'Dates'
				}
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Dispatch Quantity (in Tons)'
				}
			},
			tooltip: {
				valueSuffix: ' '
			},
			plotOptions: {
				column: {
					pointPadding: 0,
					borderWidth: 0
				}
			},
			series: [
				{
					name: 'Road',
					data:this.state.daywiseRoadQty
					,
					color:'#0767ff'
				},
				{
					name: 'Rail',
					data:this.state.daywiseRailQty,
					color:'#e8515e'
				}
			]
		}

		const optionsDispCountVehicle = {
			chart: {
				type: 'column'
			},
			title: {
				text: 'Dispatched Count of Vehicle',
				align: 'left'
			},

			xAxis: {
				categories: this.state.dayWiseDispatchDays,
				crosshair: true,
				accessibility: {
					description: 'Dates'
				}
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Count of Vehicles'
				}
			},
			tooltip: {
				valueSuffix: ' '
			},
			plotOptions: {
				column: {
					pointPadding: 0,
					borderWidth: 0
				}
			},
			series: [
				{
					name: 'Road',
					data: this.state.dayWiseDispatchDaysCountRoad
					,
					color:'#0767ff'
				},
				{
					name: 'Rail',
					data: this.state.dayWiseDispatchDaysCountRail
					,
					color:'#e8515e'
				}
			]
		}

        return (
			<>
				<div className="card">
					<div className="card-header">
						<h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
							{"Dispatch Summary"}
						</h5>
					</div>
					<div className='card-body pt-15px'>
						<HighchartsReact highcharts={Highcharts} options={options} />
					</div>
					<div className='card-body pt-15px'>
						<HighchartsReact highcharts={Highcharts} options={optionsDispCountVehicle} />
					</div>
					{/* <LoadTimeInDaysReport /> */}
					<div className='card-body pt-15px'>
						<div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px' }}>
							<div className="card-header" style={{ textAlign: 'center' }}>
								<h5>
									DISPATCH VEHICLE COUNT DASHBOARD
								</h5>
							</div>
							<div style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowX: "scroll" }}>
								<div style={{ width: '100%', }}>
									<table style={{ width: '150%', borderCollapse: 'collapse', marginBottom: '18px', height: 'auto' }}>
										<thead>
											<tr>
												<th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '5px', border: '1px solid #000', width: '100px' }}></th>

												<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '5px', border: '1px solid #000', }}>M.Yard</th>

												<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '5px', border: '1px solid #000', }}>NMA</th>
												<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>DSC</th>
												<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>CRM</th>
												<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Plate Mill</th>

												<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Pipe Mill</th>
												<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Total</th>

											</tr>
											<tr>
												<th style={{ ...tableHeaderStyle, width: '100px' }}>Date</th>
												<th style={tableHeaderStyle}>0-8 Hrs</th>
												<th style={tableHeaderStyle}>8-16 Hrs</th>
												<th style={tableHeaderStyle}>16-24 Hrs</th>
												<th style={tableHeaderStyle}>Total</th>
												<th style={tableHeaderStyle}>0-8 Hrs</th>
												<th style={tableHeaderStyle}>8-16 Hrs</th>
												<th style={tableHeaderStyle}>16-24 Hrs</th>
												<th style={tableHeaderStyle}>Total</th>
												<th style={tableHeaderStyle}>0-8 Hrs</th>
												<th style={tableHeaderStyle}>8-16 Hrs</th>
												<th style={tableHeaderStyle}>16-24 Hrs</th>
												<th style={tableHeaderStyle}>Total</th>
												<th style={tableHeaderStyle}>0-8 Hrs</th>
												<th style={tableHeaderStyle}>8-16 Hrs</th>
												<th style={tableHeaderStyle}>16-24 Hrs</th>
												<th style={tableHeaderStyle}>Total</th>
												<th style={tableHeaderStyle}>0-8 Hrs</th>
												<th style={tableHeaderStyle}>8-16 Hrs</th>
												<th style={tableHeaderStyle}>16-24 Hrs</th>
												<th style={tableHeaderStyle}>Total</th>
												<th style={tableHeaderStyle}>0-8 Hrs</th>
												<th style={tableHeaderStyle}>8-16 Hrs</th>
												<th style={tableHeaderStyle}>16-24 Hrs</th>
												<th style={tableHeaderStyle}>Total</th>
												<th style={tableHeaderStyle}>0-8 Hrs</th>
												<th style={tableHeaderStyle}>8-16 Hrs</th>
												<th style={tableHeaderStyle}>16-24 Hrs</th>
												<th style={tableHeaderStyle}>Total</th>


											</tr>
										</thead>
										<tbody>
											{dispatch_vehicle_data_arr.map((row, index) => (
												<tr key={index}>
													<td style={{ ...tableCellStyle, minWidth: '100px' }}>{(row.date)}</td>
													<td style={tableCellStyle}>{row.day_wise.MY.hrs_0_8}</td>
													<td style={tableCellStyle}>{row.day_wise.MY.hrs_8_16}</td>
													<td style={tableCellStyle}>{row.day_wise.MY.hrs_16_24}</td>
													<td style={tableCellStyle}>{row.day_wise.MY.total_hrs}</td>
													<td style={tableCellStyle}>{row.day_wise.DSC.hrs_0_8}</td>
													<td style={tableCellStyle}>{row.day_wise.DSC.hrs_8_16}</td>
													<td style={tableCellStyle}>{row.day_wise.DSC.hrs_16_24}</td>
													<td style={tableCellStyle}>{row.day_wise.DSC.total_hrs}</td>
													<td style={tableCellStyle}>{row.day_wise.CRM.hrs_0_8}</td>
													<td style={tableCellStyle}>{row.day_wise.CRM.hrs_8_16}</td>
													<td style={tableCellStyle}>{row.day_wise.CRM.hrs_16_24}</td>
													<td style={tableCellStyle}>{row.day_wise.CRM.total_hrs}</td>
													<td style={tableCellStyle}>{row.day_wise.plate_mill.hrs_0_8}</td>
													<td style={tableCellStyle}>{row.day_wise.plate_mill.hrs_8_16}</td>
													<td style={tableCellStyle}>{row.day_wise.plate_mill.hrs_16_24}</td>
													<td style={tableCellStyle}>{row.day_wise.plate_mill.total_hrs}</td>
													<td style={tableCellStyle}>{row.day_wise.pipe_mill.hrs_0_8}</td>
													<td style={tableCellStyle}>{row.day_wise.pipe_mill.hrs_8_16}</td>
													<td style={tableCellStyle}>{row.day_wise.pipe_mill.hrs_16_24}</td>
													<td style={tableCellStyle}>{row.day_wise.pipe_mill.total_hrs}</td>
													<td style={tableCellStyle}>{row.day_wise.NMA.hrs_0_8}</td>
													<td style={tableCellStyle}>{row.day_wise.NMA.hrs_8_16}</td>
													<td style={tableCellStyle}>{row.day_wise.NMA.hrs_16_24}</td>
													<td style={tableCellStyle}>{row.day_wise.NMA.total_hrs}</td>
													<td style={tableCellStyle}>{row.day_wise.total.hrs_0_8}</td>
													<td style={tableCellStyle}>{row.day_wise.total.hrs_8_16}</td>
													<td style={tableCellStyle}>{row.day_wise.total.hrs_16_24}</td>
													<td style={tableCellStyle}>{row.day_wise.total.total_hrs}</td>
												</tr>
											))}
										</tbody>

									</table>
									{dispatch_vehicle_data_arr.length == 0 &&
										<div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
									}
								</div>
							</div>

						</div>
					</div>
					
					
				</div>
				<div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			</>
        );
    }
}

export default DispatchSummaryDashboard;

